function parseGeometry (geometry) {
  const vertices = geometry.vertices
  const total = vertices.length

  const size = parseInt(Math.sqrt(total))
  const data = new Float32Array(size * size * 3)

  for (let i = 0; i < total; i++) {
    data[i * 3] = vertices[i].x
    data[i * 3 + 1] = vertices[i].y
    data[i * 3 + 2] = vertices[i].z
  }

  return data
}

export default parseGeometry
