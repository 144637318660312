import sono from 'sono'
import Detect from 'helpers/Detect'
import ExperienceSignals from 'signals/Experience'

import { DEBUG } from 'consts'

export default Vue.extend({
  name: 'message',

  template: require('./message.html'),

  data () {
    return {

    }
  },

  mounted () {
    this.hide = this.hide.bind(this)

    this.createTls()
    this.addEventsListeners()
  },

  methods: {
    createTls () {
      this.showTl = new TimelineMax({
        paused: true
      })
      this.showTl
        .from(this.$el, 1, { autoAlpha: 0 }, 0.2)
        .staggerFrom(this.$el.querySelectorAll('.message__text'), 1, { y: 20 }, 0.1, 0.2)
        .from(this.$refs.cta, 1, { y: 20 }, 0.6)

      this.hideTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          if (DEBUG.skipTutorial || Detect.getDeviceType() !== 'desktop') {
            ExperienceSignals.tutorialEnded.dispatch()
          } else {            
            ExperienceSignals.messageEnded.dispatch()
            ExperienceSignals.tutorialEnded.dispatch()
          }
        }
      })
      this.hideTl
        .to(this.$el, 1, { autoAlpha: 0 })
        .staggerTo(this.$el.querySelectorAll('.message__text'), 1, { y: -20 }, 0.04, 0)
        .to(this.$refs.cta, 1, { y: -20 }, 0.16)
    },

    addEventsListeners () {
      this.$refs.cta.addEventListener('click', this.hide, false)
    },

    start () {
      this.showTl.play()
    },

    hide() {
      sono.context.resume()
      this.hideTl.play()
    }
  },

  components: {

  }
})
