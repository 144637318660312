import screenfull from 'screenfull'

export default Vue.extend({
  name: 'fullscreen',

  template: require('./fullscreen.html'),

  data () {
    return {
      isFullScreen: false,
      isScreenfullEnabled: screenfull.enabled
    }
  },

  created () {
    this.onFullScreenChange = this.onFullScreenChange.bind(this)

    this.addEventListeners()
  },

  methods: {
    addEventListeners () {
      if (this.isScreenfullEnabled) {
        document.addEventListener(screenfull.raw.fullscreenchange, this.onFullScreenChange)
      }
    },

    onFullScreenChange () {
      this.isFullScreen = screenfull.isFullscreen
    },

    fullscreen () {
      if (this.isScreenfullEnabled) {
        this.isFullScreen = screenfull.isFullscreen
        screenfull.toggle()
      }
    }
  }
})
