import Window from 'signals/Window'
import FBOHelper from 'gl/helpers/FBOHelper'

/**
 * Renderer class
 */
class Renderer extends THREE.WebGLRenderer {

  /**
   * constructor method
   * @param {object} options Options
   */
  constructor (options) {
    super({ antialias: true, alpha: true, ...options })

    this.setSize(window.innerWidth, window.innerHeight)
    this.setPixelRatio(window.devicePixelRatio)
    this.setClearColor(0x000000, 1.0)

    this.fboHelper = new FBOHelper(this)
    this.fboHelper.setSize(Window.metrics.width, Window.metrics.height)

    this.resize = this.resize.bind(this)
    Window.resize.add(this.resize)
  }

  /**
   * resize method
   * @param {number} width  Width
   * @param {number} height Height
   */
  resize (width, height) {
    this.setSize(width, height)
    this.fboHelper.setSize(width, height)
  }
}

export default Renderer
