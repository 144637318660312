import AnimatedCurve from 'gl/core/AnimatedCurve'
import CameraControllerGUI from 'gl/gui/CameraControllerGUI'

const CONFIG = {
  CURVES: {
    DIVISIONS: 350
  }
}

class CameraController extends THREE.Object3D {
  constructor ({ camera, curves, bodyParticles }) {
    super()

    this.camera = camera
    this.curves = curves
    this.bodyParticles = bodyParticles

    this.waveOffset = new THREE.Vector3(0.4, -0.6, 0.8)

    this.cameraCurveData = curves.find(curve => curve.name === 'camera')
    this.lookAtCurveData = curves.find(curve => curve.name === 'look_at')

    // Camera
    this.cameraCurve = new AnimatedCurve({
      points: this.cameraCurveData.points,
      divisions: CONFIG.CURVES.DIVISIONS,
      loop: false,
      loopDuration: 15,
      helperColor: new THREE.Color(0x0000ff)
    })
    this.add(this.cameraCurve.helper)

    // Look at
    this.lookAtCurve = new AnimatedCurve({
      points: this.lookAtCurveData.points,
      divisions: CONFIG.CURVES.DIVISIONS,
      loop: false,
      loopDuration: 20,
      helperColor: new THREE.Color(0x00ff00)
    })
    this.add(this.lookAtCurve.helper)

    this.gui = new CameraControllerGUI({
      cameraController: this
    })
  }

  update (delta, time) {
    this.cameraCurve.update(delta, time)
    this.lookAtCurve.update(delta, time)

    this.camera.position.copy(this.cameraCurve.positionTarget)
    this.camera.lookAt(this.lookAtCurve.positionTarget)
    this.camera.controls.target.copy(this.lookAtCurve.positionTarget)

    this.bodyParticles.wave.position.copy(this.lookAtCurve.positionTarget).sub(this.waveOffset)

    this.gui.update(delta, time)
  }
}

export default CameraController
