import MeshLineGeometry from './MeshLineGeometry'
import MeshLineMaterial from './MeshLineMaterial'

class MeshLine extends THREE.Object3D {
  constructor (geometry, options = {}, widthCallback = null) {
    super()

    this.geometry = new MeshLineGeometry(geometry, widthCallback)
    this.material = new MeshLineMaterial({
      ...options,
      totalLineDistance: this.geometry.getTotalLineDistance()
    })

    this.options = options

    this.mesh = new THREE.Mesh(this.geometry, this.material)

    this.add(this.mesh)
  }

  remove () {
    super.remove()

    this.material.removeSignals()
    this.material.dispose()
  }

  update (delta, time) {
    this.material.update(delta, time)
  }
}

export default MeshLine
