import AssetsLoader from 'assets-loader'
import clamp from 'utils/math/clamp'
import sono from 'sono'

const AssetsLoaderMixin = {
  autoLoad: false,

  assets: [],

  data () {
    return {
      assetsLoaded: false,
      assetsProgress: 0
    }
  },

  created () {
    this.assets = {}
    const assets = this.$options.assets

    if (Object.keys(assets).length) {
      this.loader = AssetsLoader({
        assets,
        crossOrigin: 'anonymous',
        webAudioContext: sono.context
      })

      this.loader
        .on('error', error => {
          console.error(`[AssetsLoaderMixin] Assets loading error : ${error}`)
        })
        .on('progress', progress => {
          this.assetsProgress = clamp(AssetsLoader.stats.mbs / 1, 0, 1)
          // AssetsLoader.stats.log()
        })
        .on('complete', assets => {
          const tmpAssets = {}

          for (let i = 0, assetsLength = assets.length; i < assetsLength; i++) {
            const asset = assets[i]
            tmpAssets[asset.id] = asset.file
          }

          this.assetsLoaded = true
          this.assets = tmpAssets
          this.onAssetsLoaded(tmpAssets)
        })

      if (this.$options.autoLoad) {
        this.loader.start()
      }
    } else {
      this.assetsLoaded = true
      this.onAssetsLoaded()
    }
  },

  methods: {
    onAssetsLoaded (assets) {

    }
  }
}

export default AssetsLoaderMixin
