class MeshLineGeometry extends THREE.BufferGeometry {

  constructor (geometry, widthCallback = null) {
    super()

    this.positions = []

    this.previous = []
    this.next = []
    this.side = []
    this.width = []
    this.indices_array = []
    this.uvs = []
    this.counters = []
    this.lineDistances = []

    this.widthCallback = widthCallback

    if (geometry instanceof THREE.BufferGeometry) {
      geometry = new THREE.Geometry().fromBufferGeometry(geometry)
    }

    if (geometry instanceof THREE.Geometry) {
      for (let i = 0; i < geometry.vertices.length; i++) {
        const vertex = geometry.vertices[i]
        const c = i / geometry.vertices.length
        this.positions.push(vertex.x, vertex.y, vertex.z)
        this.positions.push(vertex.x, vertex.y, vertex.z)
        this.counters.push(c)
        this.counters.push(c)
      }
    }

    if (geometry instanceof Float32Array || geometry instanceof Array) {
      for (let i = 0; i < geometry.length; i += 3) {
        const c = i / geometry.length
        this.positions.push(geometry[i], geometry[i + 1], geometry[i + 2])
        this.positions.push(geometry[i], geometry[i + 1], geometry[i + 2])
        this.counters.push(c)
        this.counters.push(c)
      }
    }

    this.process()
  }

  process () {
    const l = this.positions.length / 6

    for (let i = 0; i < l; i++) {
      this.side.push(1)
      this.side.push(-1)
    }

    let w
    for (let i = 0; i < l; i++) {
      if (this.widthCallback) {
        w = this.widthCallback(i / (l - 1))
      } else {
        w = 1
      }

      this.width.push(w)
      this.width.push(w)
    }

    for (let i = 0; i < l; i++) {
      this.uvs.push(i / (l - 1), 0)
      this.uvs.push(i / (l - 1), 1)
    }

    let v
    if (this.compareV3(0, l - 1)) {
      v = this.copyV3(l - 2)
    } else {
      v = this.copyV3(0)
    }

    this.previous.push(v[ 0 ], v[ 1 ], v[ 2 ])
    this.previous.push(v[ 0 ], v[ 1 ], v[ 2 ])

    for (let i = 0; i < l - 1; i++) {
      v = this.copyV3(i)
      this.previous.push(v[ 0 ], v[ 1 ], v[ 2 ])
      this.previous.push(v[ 0 ], v[ 1 ], v[ 2 ])
    }

    for (let i = 1; i < l; i++) {
      v = this.copyV3(i)
      this.next.push(v[ 0 ], v[ 1 ], v[ 2 ])
      this.next.push(v[ 0 ], v[ 1 ], v[ 2 ])
    }

    if (this.compareV3(l - 1, 0)) {
      v = this.copyV3(1)
    } else {
      v = this.copyV3(l - 1)
    }

    this.next.push(v[ 0 ], v[ 1 ], v[ 2 ])
    this.next.push(v[ 0 ], v[ 1 ], v[ 2 ])

    for (let i = 0; i < l - 1; i++) {
      const n = i * 2
      this.indices_array.push(n, n + 1, n + 2)
      this.indices_array.push(n + 2, n + 1, n + 3)
    }

    this.computeLineDistances()

    this.attributes = {
      position: new THREE.BufferAttribute(new Float32Array(this.positions), 3),
      previous: new THREE.BufferAttribute(new Float32Array(this.previous), 3),
      next: new THREE.BufferAttribute(new Float32Array(this.next), 3),
      side: new THREE.BufferAttribute(new Float32Array(this.side), 1),
      width: new THREE.BufferAttribute(new Float32Array(this.width), 1),
      uv: new THREE.BufferAttribute(new Float32Array(this.uvs), 2),
      index: new THREE.BufferAttribute(new Uint16Array(this.indices_array), 1),
      counters: new THREE.BufferAttribute(new Float32Array(this.counters), 1),
      lineDistance: new THREE.BufferAttribute(new Float32Array(this.lineDistances), 1)
    }

    this.addAttribute('position', this.attributes.position)
    this.addAttribute('previous', this.attributes.previous)
    this.addAttribute('next', this.attributes.next)
    this.addAttribute('side', this.attributes.side)
    this.addAttribute('width', this.attributes.width)
    this.addAttribute('uv', this.attributes.uv)
    this.addAttribute('counters', this.attributes.counters)

    this.setIndex(this.attributes.index)
  }

  computeLineDistances () {
    const array = this.positions
    let d = 0

    for (let i = 0; i < this.positions.length; i += 3) {
      if (i > 0) {
        const x = array[i]
        const y = array[i + 1]
        const z = array[i + 2]
        const px = array[i - 3]
        const py = array[i - 2]
        const pz = array[i - 1]
        const dx = x - px
        const dy = y - py
        const dz = z - pz

        d += Math.sqrt(dx * dx + dy * dy + dz * dz)
      }

      this.lineDistances[i / 3] = d
    }
  }

  getTotalLineDistance () {
    return this.lineDistances[this.lineDistances.length - 1]
  }

  compareV3 (a, b) {
    const aa = a * 6
    const ab = b * 6
    return (this.positions[aa] === this.positions[ab]) && (this.positions[aa + 1] === this.positions[ab + 1]) && (this.positions[aa + 2] === this.positions[ab + 2])
  }

  copyV3 (a) {
    const aa = a * 6
    return [this.positions[aa], this.positions[aa + 1], this.positions[aa + 2]]
  }
}

export default MeshLineGeometry
