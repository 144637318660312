import { ASSETS_PATH, DEBUG } from 'consts'
import KeyboardSignals from 'signals/Keyboard'
import ExperienceSignals from 'signals/Experience'

export default Vue.extend({
  name: 'tutorial',

  template: require('./tutorial.html'),

  data () {
    return {
      ASSETS_PATH
    }
  },

  mounted () {
    this.start = this.start.bind(this)
    this.skipVideoHandler = this.skipVideoHandler.bind(this)

    this.createTls()

    this.addSignals()
    this.addEventsListeners()
  },

  methods: {
    createTls () {
      this.showTl = new TimelineMax({
        paused: true,
        onStart: () => {
          this.$refs.video.currentTime = 50;
          this.$refs.video.play()
        }
      })
      this.showTl
        .from(this.$refs.video, .3, { autoAlpha: 0 })
        .from(this.$refs.cta, 1, { autoAlpha: 0, y: 10 }, 2)
      
      this.hideTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          ExperienceSignals.tutorialEnded.dispatch()
        }
      })
      this.hideTl
        .to(this.$refs.video, .3, { autoAlpha: 0 }, 0)
        .to(this.$refs.cta, 1, { autoAlpha: 0, y: -10 }, 0)
        .to(this.$el, 1, { autoAlpha: 0 })
    },

    addSignals () {
      ExperienceSignals.messageEnded.add(this.start)
      if (DEBUG.KEYBOARD) {
        KeyboardSignals.keydown.add(this.skipVideoHandler)
      }
    },

    addEventsListeners () {
      this.$refs.video.addEventListener('ended', this.hide, false)
      this.$refs.cta.addEventListener('click', this.hide, false)
    },

    skipVideoHandler ({ keyCode }) {
      if (keyCode === KeyboardSignals.keys.SPACE) {
        this.hide()
        KeyboardSignals.keydown.remove(this.skipVideoHandler)
      }
    },

    start () {
      this.showTl.play()
    },

    hide () {
      this.$refs.video.pause()
      this.hideTl.play()
    }
  },

  components: {

  }
})
