import Composer from './Composer'
import Window from 'signals/Window'

/**
 * EffectComposer class
 */
class EffectComposer extends Composer {

  /**
   * constructor method
   * @param {Object} renderer Renderer
   */
  constructor (renderer) {
    super(renderer)

    this.renderer = renderer

    this.onResize = ::this.onResize
    this.addSignals()
  }

  addSignals () {
    Window.resize.add(this.onResize)
  }

  removeSignals () {
    Window.resize.remove(this.onResize)
  }

  dispose () {
    super.dispose()

    this.removeSignals()
  }

  /**
   * onResize method
   * @param {number} width  Window width
   * @param {number} height Window height
   */
  onResize (width, height) {
    this.setSize(width * this.renderer.getPixelRatio(), height * this.renderer.getPixelRatio())
  }
}

export default EffectComposer
