import Detect from 'helpers/Detect'
import { PostPass, BloomUnrealPass } from 'gl/postProcessing/passes'

export default {
  active: (Detect.getDeviceType() === 'desktop'),
  passes: [
    {
      active: true,
      name: 'bloomPass',
      constructor: new BloomUnrealPass({
        resolution: new THREE.Vector2(256, 256),
        strength: 0.2,
        radius: 0.15,
        threshold: 0.92
      })
    },
    {
      active: true,
      name: 'postPass',
      constructor: new PostPass()
    }
  ]
}
