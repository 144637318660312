import Signal from 'min-signal'

class Sensor {
  constructor () {
    this.currentBpm = 0

    this.bpm = new Signal()
  }
}

export default new Sensor()
