function drawCircle ({ ctx, stroke = 'transparent', fill = 'transparent', lineWidth = 1, center = { x: 0, y: 0 }, radius = 100, startAngle = 0, endAngle = Math.PI * 2, anticlockwise = false }) {
  ctx.strokeStyle = stroke
  ctx.fillStyle = fill
  ctx.lineWidth = lineWidth
  ctx.lineCap = 'round'

  ctx.beginPath()
  ctx.arc(center.x, center.y, radius, startAngle, endAngle, anticlockwise)

  ctx.stroke()
  ctx.fill()

  ctx.closePath()
}

function drawLinesCircle ({ ctx, stroke = 'transparent', fill = 'transparent', radius = 100, lineWidth = 1, nbLines = 10, lineLength = 10 }) {
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.fillStyle = fill

  for (let i = 0; i < nbLines; i++) {
    ctx.save()
    ctx.rotate(i / nbLines * (Math.PI * 2))

    ctx.beginPath()
    ctx.moveTo(radius, 0)
    ctx.lineTo(radius - lineLength, 0)
    ctx.stroke()

    ctx.restore()
  }
}

export default {
  drawCircle,
  drawLinesCircle
}
