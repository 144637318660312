import Folder from './Folder'

class BodyParticlesGUI extends Folder {
  constructor ({ body }) {
    super({ folderName: 'Body particles' })

    this.body = body

    // Render material
    const renderMaterialFolder = this.folder.addFolder('Render material')

    // --> Uniforms
    const renderMaterialUniformsFolder = renderMaterialFolder.addFolder('Uniforms')

    const renderMaterialUniforms = this.body.renderMaterial.uniforms

    // ==> Noise
    const renderMaterialNoiseFolder = renderMaterialUniformsFolder.addFolder('Wave noise')
    renderMaterialNoiseFolder.add(renderMaterialUniforms.uNoiseAmplitude, 'value', 0, 5, 0.001).name('Amplitude')
    renderMaterialNoiseFolder.add(renderMaterialUniforms.uNoiseFrequency, 'value', 0, 100, 0.01).name('Frequency')
    renderMaterialNoiseFolder.add(renderMaterialUniforms.uNoiseSpeed, 'value', 0, 30, 0.001).name('Speed')

    // ==> Fly
    const renderMaterialFlyFolder = renderMaterialUniformsFolder.addFolder('Fly')
    renderMaterialFlyFolder.add(renderMaterialUniforms.uFlyAmplitude, 'value', 0, 0.5, 0.001).name('Amplitude')
    renderMaterialFlyFolder.add(renderMaterialUniforms.uFlyFrequency, 'value', 0, 100, 0.01).name('Frequency')
    renderMaterialFlyFolder.add(renderMaterialUniforms.uFlySpeed, 'value', 0, 10, 0.001).name('Speed')

    renderMaterialUniformsFolder.add(renderMaterialUniforms.uWindowHeight, 'value').name('Window height')
    renderMaterialUniformsFolder.add(renderMaterialUniforms.uPointSize, 'value', 0, 0.1, 0.001).name('Point size')

    // --> Material
    renderMaterialFolder.add(this.body.renderMaterial, 'transparent').name('Transparent')

    // Wave
    const waveFolder = this.folder.addFolder('Wave')
    waveFolder.add(this.body.wave, 'visible').name('Visible')
    waveFolder.add(this.body, 'waveRadius', 0.01, 4, 0.001)
      .onChange(v => {
        this.body.wave.scale.set(v, v, v)
      })
      .name('Radius')

    const wavePositionFolder = waveFolder.addFolder('Position')
    wavePositionFolder.add(this.body.wave.position, 'x', -30, 30, 0.01).name('X')
    wavePositionFolder.add(this.body.wave.position, 'y', -30, 30, 0.01).name('Y')
    wavePositionFolder.add(this.body.wave.position, 'z', -30, 30, 0.01).name('Z')
  }
}

export default BodyParticlesGUI
