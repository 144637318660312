import GUI from 'gl/helpers/GUI'

import vertexShader from 'gl/shaders/ring.vert'
import fragmentShader from 'gl/shaders/ring.frag'

class Ring extends THREE.Object3D {
  constructor ({ ringGeometry }) {
    super()

    this.ringMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uColor: { value: new THREE.Color(0x939393) },
        uRimLight: { value: true },
        uRimLightIntensity: { value: 1.3 },
        ...THREE.UniformsLib.fog
      },
      vertexShader,
      fragmentShader,
      fog: true
    })

    this.ringMesh = new THREE.Mesh(ringGeometry, this.ringMaterial)
    this.ringMesh.position.set(1.8, 12.245, -3.77)
    this.ringMesh.rotation.set(-0.1, 0.27, 0)

    this.add(this.ringMesh)

    this.addGUI()
  }

  addGUI () {
    const ringFolder = GUI.addFolder('Ring')

    const ringMaterialFolder = ringFolder.addFolder('Material')
    ringMaterialFolder.add(this.ringMesh.material, 'opacity', 0, 1, 0.01).name('Opacity')
    ringMaterialFolder.add(this.ringMesh.material, 'transparent').name('Transparent')
    ringMaterialFolder.add(this.ringMesh.material, 'depthTest').name('Depth test')
    ringMaterialFolder.add(this.ringMesh.material, 'wireframe').name('Wireframe')

    // --> Position
    const ringPositionFolder = ringFolder.addFolder('Position')
    ringPositionFolder.add(this.ringMesh.position, 'x', -30, 30, 0.001).name('X')
    ringPositionFolder.add(this.ringMesh.position, 'y', -30, 30, 0.001).name('Y')
    ringPositionFolder.add(this.ringMesh.position, 'z', -30, 30, 0.001).name('Z')

    // --> Rotation
    const ringRotationFolder = ringFolder.addFolder('Rotation')
    ringRotationFolder.add(this.ringMesh.rotation, 'x', -Math.PI * 2, Math.PI * 2, 0.01).name('X')
    ringRotationFolder.add(this.ringMesh.rotation, 'y', -Math.PI * 2, Math.PI * 2, 0.01).name('Y')
    ringRotationFolder.add(this.ringMesh.rotation, 'z', -Math.PI * 2, Math.PI * 2, 0.01).name('Z')
  }
}

export default Ring
