module.exports=[
  {
    "id": 210633688,
    "artist": "Kiiara",
    "title": "Gold",
    "album": "Low Kii Savage",
    "year": 2015,
    "bpm": 113
  },
  {
    "id": 208090969,
    "artist": "Petit Biscuit",
    "title": "Memories",
    "album": "",
    "year": 2015,
    "bpm": 110
  },
  {
    "id": 273236945,
    "artist": "A. G. Cook",
    "title": "Superstar",
    "album": "",
    "year": 2016,
    "bpm": 120
  },
  {
    "id": 257659076,
    "artist": "D.R.A.M.",
    "title": "Broccoli",
    "album": "Big Baby D.R.A.M.",
    "year": 2016,
    "bpm": 146
  },
  {
    "id": 305230900,
    "artist": "Odezenne",
    "title": "Souffle le vent",
    "album": "Dolziger Str. 2",
    "year": 2015,
    "bpm": 102
  },
  {
    "id": 68428743,
    "artist": "Blackmail",
    "title": "Turn the heater off",
    "album": "Bones",
    "year": 2012,
    "bpm": 101
  },
  {
    "id": 28516561,
    "artist": "Stand High Patrol",
    "title": "Television Addict",
    "album": "Pupajim",
    "year": 2011,
    "bpm": 85
  },
  {
    "id": 21559550,
    "artist": "Nujabes",
    "title": "Aruarian Dance",
    "album": "Departure",
    "year": 2004,
    "bpm": 99
  },
  {
    "id": 65833474,
    "artist": "Ludwig Van Beethoven",
    "title": "Fur Elise",
    "album": "",
    "year": 1810,
    "bpm": 70
  },
  {
    "id": 193270658,
    "artist": "Skrillex & Diplo (feat Justin Bieber)",
    "title": "Where Are Ü Now",
    "album": "Skrillex and Diplo Present Jack Ü",
    "year": 2015,
    "bpm": 140
  },
  {
    "id": 101426244,
    "artist": "Yiruma",
    "title": "Rivers Flow In You",
    "album": "First Love",
    "year": 2001,
    "bpm": 145
  },
  {
    "id": 45784550,
    "artist": "Savant",
    "title": "Splinter",
    "album": "Vario",
    "year": 2012,
    "bpm": 127
  },
  {
    "id": 49397330,
    "artist": "Michael Buble",
    "title": "Crazy Love",
    "album": "Crazy Love",
    "year": 2009,
    "bpm": 82
  },
  {
    "id": 3599578,
    "artist": "Bruno Mars",
    "title": "Count On Me",
    "album": "Doo-Wops & Hooligans",
    "year": 2010,
    "bpm": 89
  },
  {
    "id": 57645202,
    "artist": "Red Hot Chili Peppers",
    "title": "Californication",
    "album": "Californication",
    "year": 2010,
    "bpm": 96
  },
  {
    "id": 12651550,
    "artist": "Baha Men",
    "title": "Who Let the Dogs Out?",
    "album": "Who Let the Dogs Out",
    "year": 2000,
    "bpm": 129
  },
  {
    "id": 11932626,
    "artist": "Chiddy Bang",
    "title": "Opposite Of Adults",
    "album": "The Swelly Express",
    "year": 2009,
    "bpm": 96
  },
  {
    "id": 88843494,
    "artist": "DIMMI",
    "title": "Promesses",
    "album": "Promesses",
    "year": 2014,
    "bpm": 127
  },
  {
    "id": 176907508,
    "artist": "The Geek x Vrv",
    "title": "Say it",
    "album": "",
    "year": 2014,
    "bpm": 88
  },
  {
    "id": 241733865,
    "artist": "Goldwash",
    "title": "But U Won't",
    "album": "",
    "year": 2016,
    "bpm": 113
  },
  {
    "id": 39635386,
    "artist": "Gramatik",
    "title": "Just Jammin",
    "album": "Street Bangerz Vol. 2",
    "year": 2009,
    "bpm": 90
  },
  {
    "id": 39637570,
    "artist": "Gramatik",
    "title": "Muy Tranquilo",
    "album": "SB3",
    "year": 2010,
    "bpm": 90
  },
  {
    "id": 110922762,
    "artist": "Keys N Krates",
    "title": "Dum Dee Dum",
    "album": "SOLOW",
    "year": 2013,
    "bpm": 160
  },
  {
    "id": 110922160,
    "artist": "Keys N Krates",
    "title": "I Just Can't Deny",
    "album": "SOLOW",
    "year": 2013,
    "bpm": 154
  },
  {
    "id": 81807530,
    "artist": "Keys N Krates",
    "title": "Treat Me Right",
    "album": "SOLOW",
    "year": 2013,
    "bpm": 150
  },
  {
    "id": 29029274,
    "artist": "ProleteR",
    "title": "April Showers",
    "album": "Curses From Past Times",
    "year": 2011,
    "bpm": 94
  },
  {
    "id": 73411817,
    "artist": "Soul Square (feat. Blezz)",
    "title": "Take It Back",
    "album": "",
    "year": 2010,
    "bpm": 94
  },
  {
    "id": 68766766,
    "artist": "Supersci (feat Chords)",
    "title": "On the Grind",
    "album": "",
    "year": 2006,
    "bpm": 92
  },
  {
    "id": 90929668,
    "artist": "FAUVE",
    "title": "Rub a dub",
    "album": "Blizzard",
    "year": 2013,
    "bpm": 95
  },
  {
    "id": 90929665,
    "artist": "FAUVE",
    "title": "Haut les coeurs",
    "album": "Blizzard",
    "year": 2013,
    "bpm": 110
  },
  {
    "id": 75078287,
    "artist": "Cash Cash",
    "title": "Overtime",
    "album": "",
    "year": 2013,
    "bpm": 114
  },
  {
    "id": 92050833,
    "artist": "B.o.B (feat 2 Chainz)",
    "title": "Headband",
    "album": "Underground Luxury",
    "year": 2013,
    "bpm": 97
  },
  {
    "id": 15747461,
    "artist": "Ratatat",
    "title": "El Pico",
    "album": "Ratatat",
    "year": 2004,
    "bpm": 98
  },
  {
    "id": 141870853,
    "artist": "Weezer",
    "title": "Island in the Sun",
    "album": "Weezer",
    "year": 2001,
    "bpm": 115
  },
  {
    "id": 4817655,
    "artist": "Bob Marley & The Wailers",
    "title": "Jamming",
    "album": "Babylon by Bus",
    "year": 1978,
    "bpm": 124
  },
  {
    "id": 4544839,
    "artist": "Damian Marley",
    "title": "Welcome to Jamrock",
    "album": "Welcome to Jamrock",
    "year": 2005,
    "bpm": 77
  },
  {
    "id": 149243221,
    "artist": "Deep Purple",
    "title": "Smoke On The Water",
    "album": "Machine Head",
    "year": 1972,
    "bpm": 114
  },
  {
    "id": 105085838,
    "artist": "AC/DC",
    "title": "Thunderstruck",
    "album": "The Razors Edge",
    "year": 1990,
    "bpm": 134
  },
  {
    "id": 154016669,
    "artist": "AC/DC",
    "title": "Back in Black",
    "album": "Back in Black",
    "year": 1980,
    "bpm": 93
  },
  {
    "id": 36372398,
    "artist": "Fools Graden",
    "title": "Lemon Tree",
    "album": "Dish of the Day",
    "year": 1995,
    "bpm": 143
  },
  {
    "id": 92630397,
    "artist": "Elephanz",
    "title": "Stereo",
    "album": "",
    "year": 2011,
    "bpm": 100
  },
  {
    "id": 231155880,
    "artist": "Gorillaz",
    "title": "Clint Eastwood",
    "album": "Gorillaz",
    "year": 2001,
    "bpm": 168
  },
  {
    "id": 251964694,
    "artist": "Polo & Pan",
    "title": "Bakara",
    "album": "Canopée - EP",
    "year": 2016,
    "bpm": 115
  },
  {
    "id": 135662350,
    "artist": "Darius",
    "title": "Hot Hands",
    "album": "Romance",
    "year": 2014,
    "bpm": 110
  },
  {
    "id": 5117784,
    "artist": "Darius",
    "title": "Maliblue",
    "album": "Velour",
    "year": 2012,
    "bpm": 101
  },
  {
    "id": 122327378,
    "artist": "Alina Baraz (feat Galimatias)",
    "title": "Make You Feel",
    "album": "Urban Flora EP",
    "year": 2015,
    "bpm": 100
  },
  {
    "id": 188294794,
    "artist": "L'impératrice",
    "title": "Vanille fraise",
    "album": "",
    "year": 2015,
    "bpm": 103
  }
]

/*
https://api.soundcloud.com/resolve.json?client_id=e2a6681bccff23130855618e14c481af&url=
*/
