import 'whatwg-fetch'
import 'gsap'

import AssetsLoaderMixin from 'mixins/AssetsLoaderMixin'
import assets from 'data/manifest'

import SensorService from 'services/Sensor'
import ExperienceSignals from 'signals/Experience'

import Detect from 'helpers/Detect'

import Loader from 'components/loader/loader'
import Message from 'components/message/message'
import Tutorial from 'components/tutorial/tutorial'
import Logo from 'components/logo/logo'
import Footer from 'components/footer/footer'
import Fullscreen from 'components/fullscreen/fullscreen'
import WebGL from 'components/webgl/webgl'
import UserInfos from 'components/user-infos/user-infos'
import BpmInfos from 'components/bpm-infos/bpm-infos'
import SoundInfos from 'components/sound-infos/sound-infos'
import SensorProgress from 'components/sensor-progress/sensor-progress'
import HeartCanvas from 'components/heart-canvas/heart-canvas'

import { DEBUG } from 'consts'

document.addEventListener('DOMContentLoaded', () => {
  const application = new Vue({ // eslint-disable-line
    name: 'application',

    el: '#application',

    autoLoad: true,

    assets,

    mixins: [AssetsLoaderMixin],

    data () {
      return {
        skipTutorial: DEBUG.SKIP_TUTORIAL
      }
    },

    mounted () {
      this.sensorService = new SensorService()
      Detect.addClasses()
    },

    methods: {
      onAssetsLoaded (assets) {
        this.$refs.message.start()
      }
    },

    components: {
      'loader': Loader,
      'message': Message,
      'tutorial': Tutorial,
      'logo': Logo,
      'v-footer': Footer,
      'fullscreen': Fullscreen,
      'webgl': WebGL,
      'user-infos': UserInfos,
      'bpm-infos': BpmInfos,
      'sound-infos': SoundInfos,
      'sensor-progress': SensorProgress,
      'heart-canvas': HeartCanvas
    }
  })
})
