import sono from 'sono'

class SoundManager {
  constructor (assets) {
    this.assets = assets

    this.heartbeat = sono.createSound(assets.heartbeat)
    this.heartbeat.loop = true

    this.calculating = sono.createSound(assets.calculating)
    this.calculating.loop = true
    this.calculating.volume = 0

    this.completed = sono.createSound(assets.completed)

    this.camera = sono.createSound(assets.camera)
  }
}

export default SoundManager
