/**
 * Normalize a value between two bounds
 *
 * @param  {number} v   Value to normalize
 * @param  {number} min Minimum boundary
 * @param  {number} max Maximum boundary
 * @return {number}     Normalized value
 */
export default function normalize (v, min, max) {
  return (v - min) / (max - min)
}
