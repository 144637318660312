import { ASSETS_PATH } from 'consts'

import ExperienceSignals from 'signals/Experience'

export default Vue.extend({
  name: 'sound-infos',

  template: require('./sound-infos.html'),

  data () {
    return {
      ASSETS_PATH,
      trackInfos: {},
      artist: '',
      title: '',
      album: '',
      year: '',
      bpm: 0
    }
  },

  mounted () {
    this.staggers = this.$el.querySelectorAll('.js-stagger')
    this.separator = this.$el.querySelector('.js-separator')

    this.isFirst = true
    this.isNoSoundPlayed = false

    this.createTls()

    this.updateTrackInfosHandler = this.updateTrackInfosHandler.bind(this)
    this.noSoundPlayingHandler = this.noSoundPlayingHandler.bind(this)

    this.addSignals()
  },

  methods: {
    addSignals () {
      ExperienceSignals.updateTrackInfos.add(this.updateTrackInfosHandler)
      ExperienceSignals.noSoundPlaying.add(this.noSoundPlayingHandler)
    },
    updateTrackInfosHandler (trackInfos) {
      this.trackInfos = trackInfos

      if (this.isFirst === true || this.isNoSoundPlayed === true) {
        this.changeInfos()
        this.showTl.play(0)
        this.isFirst = false
        this.isNoSoundPlayed = false
      } else {
        this.hideAndShowTl.play(0)
      }
    },
    noSoundPlayingHandler () {
      this.hideTl.play(0)
      this.isNoSoundPlayed = true
    },
    changeInfos () {
      this.artist = (this.trackInfos.artist) ? this.trackInfos.artist : 'Artist not found'
      this.title = (this.trackInfos.title) ? this.trackInfos.title : 'Title not found'
      this.album = (this.trackInfos.album) ? this.trackInfos.album : 'Album not found'
      this.year = (this.trackInfos.year) ? this.trackInfos.year : 'Year not found'
      this.bpm = (this.trackInfos.bpm) ? ('000' + this.trackInfos.bpm).slice(-3) : '000'
    },
    createTls () {
      this.hideTl = new TimelineMax({ paused: true })
      this.hideTl
        .staggerFromTo(this.staggers, 0.8, { autoAlpha: 1, x: 0 }, { autoAlpha: 0, x: -50, ease: Power2.easeOut }, 0.1)
        .fromTo(this.separator, 0.8, { autoAlpha: 1, scaleX: 1 }, { autoAlpha: 0, scaleX: 0, transformOrigin: 'left', ease: Power2.easeOut }, 0.3)

      this.hideAndShowTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          this.changeInfos()
          this.showTl.play(0)
        }
      })
      this.hideAndShowTl
        .staggerFromTo(this.staggers, 0.8, { autoAlpha: 1, x: 0 }, { autoAlpha: 0, x: -50, ease: Power2.easeOut }, 0.1)
        .fromTo(this.separator, 0.8, { autoAlpha: 1, scaleX: 1 }, { autoAlpha: 0, scaleX: 0, transformOrigin: 'left', ease: Power2.easeOut }, 0.3)

      this.showTl = new TimelineMax({ paused: true })
      this.showTl
        .staggerFromTo(this.staggers, 0.8, { autoAlpha: 0, x: 50 }, { autoAlpha: 1, x: 0, ease: Power2.easeOut }, 0.1)
        .fromTo(this.separator, 0.8, { autoAlpha: 0, scaleX: 0 }, { autoAlpha: 1, scaleX: 1, transformOrigin: 'right', ease: Power2.easeOut }, 0.3)
    }
  },

  components: {

  }
})
