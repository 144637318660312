import Folder from './Folder'

class CameraControllerGUI extends Folder {
  constructor ({ cameraController }) {
    super({ folderName: 'Camera controller' })

    this.cameraController = cameraController

    const cameraCurveFolder = this.folder.addFolder('Camera curve')
    const lookAtCurveFolder = this.folder.addFolder('LookAt curve')

    this.cameraController.cameraCurve.createGUI(cameraCurveFolder)
    this.cameraController.lookAtCurve.createGUI(lookAtCurveFolder)

    this.sphere = new THREE.Mesh(new THREE.SphereGeometry(0.2, 8, 8), new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true, fog: false }))
    this.sphere.name = 'Sphere helper'
    this.cameraController.add(this.sphere)
  }

  update (delta, time) {
    this.sphere.position.copy(this.cameraController.lookAtCurve.positionTarget)
  }
}

export default CameraControllerGUI
