import GUI from 'gl/helpers/GUI'

class Folder {
  constructor ({ folderName } = {}) {
    this.folderName = folderName

    this.folder = GUI.addFolder(this.folderName)
  }

  remove () {
    GUI.removeFolder(this.folderName)
    this.removeSignals()
  }

  removeController (controller) {
    this.folder.remove(controller)
  }

  addSignals () {

  }

  removeSignals () {

  }
}

export default Folder
