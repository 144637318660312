import parseVec3 from 'utils/gl/parse-vector-3'

class Curve extends THREE.CatmullRomCurve3 {
  constructor ({ points, divisions = 100, helperColor = new THREE.Color(0xff0000) }) {
    super(parseVec3(points))

    this.points = this.getSpacedPoints(divisions)
    this.helperColor = helperColor

    this.createHelpers()
  }

  createHelpers () {
    this.geometry = new THREE.Geometry()
    this.geometry.vertices = this.points

    const material = new THREE.LineBasicMaterial({ linewidth: 1, color: this.helperColor, fog: false })
    this.helper = new THREE.Line(this.geometry, material)
    this.helper.name = 'Line helper'
  }

  createGUI (folder) {
    folder.add(this.helper, 'visible', 0.1, 1, 0.01).name('Curve helper')
  }

  setOffset (offset = new THREE.Vector3()) {
    for (let i = 0, pointsLength = this.points.length; i < pointsLength; i++) {
      this.points[i].add(offset)
    }

    return this.points
  }

  setScale (scale = 1) {
    for (let i = 0, pointsLength = this.points.length; i < pointsLength; i++) {
      this.points[i].multiplyScalar(scale)
    }

    return this.points
  }
}

export default Curve
