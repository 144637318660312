export default {
  ASSETS_PATH: './assets',
  SERVER: {
    IP: 'localhost',
    PORT: 8000
  },
  DEBUG: {
    SENSOR: false, // Log sensor data received by the server (Socket.io)
    LOADER: false, // Log draco loader debug informations
    KEYBOARD: false, // Enable keyboard shortcuts
    SKIP_TUTORIAL: true, // Skip tutorial at init
    SOUNDCLOUD_FAKE: true // Get always the same data track to reduce API request
  },
  SOUNDCLOUD: {
    BASE_URL: 'https://api.soundcloud.com',
    CLIENT_ID: 'VAKXyGl4AHmIOMcrAKdPP6jM2qohmmsk',
    LIMIT: 30 // Limit of tracks to retrieve
  },
  BPM: {
    DEFAULT: 110,
    MIN: 60,
    MAX: 160,
    GAP: 10
  },
  EXPERIENCE: {
    SOUNDS_INTERVAL: 15,
    WAVE_DURATION: 4
  }
}
