import sono from 'sono'
import Clubber from 'clubber'

import SceneGUI from 'gl/gui/SceneGUI'
import DracoLoader from 'gl/loaders/DracoLoader'

import { EXPERIENCE, BPM } from 'consts'
import Sensor from 'signals/Sensor'
import ExperienceSignals from 'signals/Experience'

import Soundcloud from 'services/Soundcloud'

import PostProcessing from 'gl/postProcessing/PostProcessing'
import postProcessingConfig from 'config/postProcessing'

import SoundManager from './SoundManager'

import Body from 'gl/entities/Body/Body'
import Ring from 'gl/entities/Ring/Ring'
import Heart from 'gl/entities/Heart/Heart'
import CameraController from 'gl/entities/CameraController/CameraController'

class Scene extends THREE.Scene {
  constructor (Renderer, Camera, assets) {
    super()

    this.renderer = Renderer
    this.camera = Camera
    this.assets = assets

    this.currentCamera = this.camera

    this.postProcessing = new PostProcessing({
      scene: this,
      camera: this.camera,
      renderer: this.renderer,
      config: postProcessingConfig
    })

    this.loader = new DracoLoader()

    this.clubber = new Clubber({
      size: 2048,
      mute: true,
      context: sono.context
    })
    this.band = this.clubber.band({
      template: '0123', // Alternately [0, 1, 2, 3]
      from: 1, // Minimum midi note to watch (horizontal)
      to: 128, // Maximum midi note, up to 160 (horizontal)
      low: 64, // Low velocity/power threshold (vertical)
      high: 128, // High velocity/power threshold (vertical)
      smooth: [0.1, 0.1, 0.1, 0.1], // Exponential smoothing factors for the values
      adapt: [1, 1, 1, 1], // Adaptive bounds setup
      snap: 0.33
    })
    this.soundVector = new THREE.Vector4()

    this.soundManager = new SoundManager(this.assets)

    this.trackInfos = null
    this.sound = undefined
    this.isSoundPlaying = false
    this.isTlsPlayed = false
    this.timerInterval = 0
    this.isSoundMuted = false
    this.isSoundFading = false

    this.updateSoundHandler = this.updateSoundHandler.bind(this)
    this.tutorialEndedHandler = this.tutorialEndedHandler.bind(this)
    this.speedHandler = this.speedHandler.bind(this)
    this.bpmHandler = this.bpmHandler.bind(this)
    this.bpmFound = this.bpmFound.bind(this)
    this.changeSound = this.changeSound.bind(this)
    this.firstSoundFoundHandler = this.firstSoundFoundHandler.bind(this)

    this.addSignals()
    this.create()
  }

  addSignals () {
    ExperienceSignals.updateSound.add(this.updateSoundHandler)
    ExperienceSignals.tutorialEnded.add(this.tutorialEndedHandler)
    ExperienceSignals.firstSoundFound.add(this.firstSoundFoundHandler)
  }

  firstSoundFoundHandler () {
    this.startWaveHandler()
  }

  updateSoundHandler ({ sound, trackInfos }) {
    this.clubber.listen(sound.gain)

    this.trackInfos = trackInfos

    if (this.sound === undefined) { // First sound
      this.sound = sound
      this.sound.volume = 0
      this.sound.fade(1, 3)
    } else {
      this.sound.fade(0, 3)
      this.isSoundFading = true
      setTimeout(() => {
        this.sound.destroy()
        this.sound = sound
        this.sound.play()
        if (this.isSoundMuted === false) {
          this.sound.fade(1, 3)
        }
        this.isSoundPlaying = true
        ExperienceSignals.updateTrackInfos.dispatch(this.trackInfos)
      }, 3000)

      setTimeout(() => { this.isSoundFading = false }, 6000)
    }
  }

  speedHandler ({ bpm }) {
    const speed = (bpm / 60) * 3.0
    const tweenDuration = 1

    if (speed === 0 && this.isSoundMuted === false && this.isSoundFading === false && this.sound) {
      this.isSoundMuted = true
      this.sound.fade(0, tweenDuration)
      this.soundManager.calculating.play()
      this.soundManager.calculating.fade(1, 0.3)
      ExperienceSignals.noSoundPlaying.dispatch()
    } else if (speed !== 0 && this.isSoundMuted === true && this.isSoundFading === false && this.sound) {
      this.isSoundMuted = false
      this.isSoundPlaying = false
      this.timerInterval = 0
      this.changeSound()
      this.soundManager.calculating.fade(0, 0.3)
      setTimeout(() => {
        this.soundManager.calculating.stop()
        this.soundManager.completed.play()
      }, 300)
    }

    this.heart.updateSpeed(speed, tweenDuration)
    this.body.bodyParticles.updateSpeed(speed, tweenDuration)
    for (let i = 0; i < this.body.bodyVeins.meshLines.length; i++) {
      this.body.bodyVeins.updateSpeed(speed, tweenDuration)
      this.body.bodyVeins.meshLines[i].material.updateSpeed(speed, tweenDuration)
    }
    ExperienceSignals.updateSpeed.dispatch(speed, tweenDuration)
  }

  bpmFound (bpm) {
    Soundcloud.searchTrack(bpm)
  }

  bpmHandler ({ bpm, fake }) {
    if (bpm >= BPM.MIN && bpm <= BPM.MAX) {
      this.bpmFound(bpm)
      Sensor.bpm.remove(this.bpmHandler)
    }
  }

  changeSound () {
    Sensor.bpm.add(this.bpmHandler)
  }

  tutorialEndedHandler () {
    this.start()
  }

  create () {
    this.createEnvironment()
    this.createEntities()
    this.createTls()

    this.addGUI()
  }

  start () {
    sono.context.resume()
    this.handTl.play()
  }

  addGUI () {
    this.gui = new SceneGUI({ scene: this })
  }

  createTls () {
    this.handTl = new TimelineMax({
      paused: true,
      onStart: () => {
        this.soundManager.heartbeat.volume = 0
        this.soundManager.heartbeat.play()
        this.soundManager.heartbeat.fade(1, 1)
      },
      onComplete: () => {
        Sensor.bpm.add(this.speedHandler)
        ExperienceSignals.handEnded.dispatch()
      }
    })
    this.handTl
      .to(this.body.bodyParticles.renderMaterial.uniforms.uAlpha, 8, { value: 1 }, 0)
      .to(this.cameraController.cameraCurve, 8, { progress: 0.90, ease: Sine.easeOut }, 0)
      .to(this.cameraController.lookAtCurve, 4, { progress: 0.05, ease: Sine.easeOut }, 0)
      .from(this.ring.ringMesh.position, 4, { x: 1.6, y: 12.1, z: -4.56, ease: Sine.easeInOut }, 4)

    this.waveTl = new TimelineMax({
      paused: true,
      onStart: () => {
        this.soundManager.heartbeat.fade(0, 3)
        this.soundManager.camera.play()
        this.body.bodyVeins.start()
      },
      onComplete: () => {
        this.soundManager.heartbeat.destroy()
        this.soundManager.camera.destroy()
        this.isTlsPlayed = true
        ExperienceSignals.waveEnded.dispatch()
      }
    })
    this.waveTl
      .to(this.body.bodyParticles.wave.scale, EXPERIENCE.WAVE_DURATION, { x: 0.8, y: 0.8, z: 0.8 }, 0)
      .to(this.cameraController.cameraCurve, EXPERIENCE.WAVE_DURATION, { progress: 1, ease: Sine.easeInOut }, 0)
      .to(this.cameraController.lookAtCurve, 5, { progress: 1, ease: Sine.easeInOut }, 0)
      .addCallback(() => {
        this.heart.start()
        this.sound.play()
        if (this.isSoundMuted === false) {
          this.sound.fade(1, 3)
        }
        this.isSoundPlaying = true
        ExperienceSignals.updateTrackInfos.dispatch(this.trackInfos)
      }, EXPERIENCE.WAVE_DURATION)
  }

  createEnvironment (color = '#181717') {
    this.environmentColor = color

    this.fogColor = this.environmentColor
    this.fog = new THREE.Fog(new THREE.Color(this.fogColor), 0, 7.8)

    this.backgroundColor = this.environmentColor
    this.background = this.backgroundColor

    this.renderer.setClearColor(this.environmentColor, 1)
  }

  createEntities () {
    this.body = new Body({
      renderer: this.renderer,
      bodyGeometry: this.loader.parse(this.assets.body),
      assets: this.assets
    })
    this.add(this.body)

    this.ring = new Ring({ ringGeometry: this.loader.parse(this.assets.ring) })
    this.add(this.ring)

    this.heart = new Heart()
    this.add(this.heart)

    this.cameraController = new CameraController({
      camera: this.camera,
      curves: this.assets.curves,
      bodyParticles: this.body.bodyParticles
    })
    this.add(this.cameraController)
  }

  startWaveHandler () {
    this.waveTl.play()
  }

  update (delta, time) {
    this.postProcessing.update(delta, time)
    this.postProcessing.camera = this.currentCamera

    this.renderer.fboHelper.update()

    this.camera.update(delta)
    this.gui.update(delta, time)

    this.heart.update(delta, time)
    this.body.update(delta, time)
    this.cameraController.update(delta, time)

    this.clubber.update(time * 1000)
    this.band(this.soundVector)

    ExperienceSignals.soundAnalysis = this.soundVector

    this.heart.material.uniforms.uSound.value = this.soundVector
    this.body.bodyParticles.renderMaterial.uniforms.uSound.value = this.soundVector

    for (let i = 0; i < this.body.bodyVeins.meshLines.length; i++) {
      this.body.bodyVeins.meshLines[i].material.uniforms.uSound.value = this.soundVector
    }

    if (this.isTlsPlayed === true && this.isSoundPlaying === true && this.isSoundMuted === false) {
      this.timerInterval += delta

      if (this.timerInterval >= EXPERIENCE.SOUNDS_INTERVAL) {
        this.timerInterval = 0
        this.isSoundPlaying = false
        this.changeSound()
      }
    }
  }
}

export default Scene
