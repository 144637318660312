import { DEBUG } from 'consts'

const DracoModule = window.Module

class DracoLoader {
  constructor (manager = THREE.DefaultLoadingManager) {
    this.manager = manager
    this.materials = null
  }

  load (url, onLoad, onProgress, onError) {
    const loader = new THREE.FileLoader(this.manager)
    loader.setPath(this.path)
    loader.setResponseType('arraybuffer')

    loader.load(url, blob => {
      onLoad(this.parse(blob))
    }, onProgress, onError)
  }

  setPath (value) {
    this.path = value
  }

  parse (rawBuffer) {
    // Here is how to use Draco Javascript decoder and get the geometry
    const buffer = new DracoModule.DecoderBuffer()
    buffer.Init(new Int8Array(rawBuffer), rawBuffer.byteLength)
    const wrapper = new DracoModule.WebIDLWrapper()

    // Determine what type is this file, mesh or point cloud
    const geometryType = wrapper.GetEncodedGeometryType(buffer)

    return this.convertDracoGeometryTo3JS(wrapper, geometryType, buffer)
  }

  convertDracoGeometryTo3JS (wrapper, geometryType, buffer) {
    let dracoGeometry

    if (geometryType === DracoModule.TRIANGULAR_MESH) {
      dracoGeometry = wrapper.DecodeMeshFromBuffer(buffer)
    } else {
      dracoGeometry = wrapper.DecodePointCloudFromBuffer(buffer)
    }

    DracoModule.destroy(buffer)

    let numFaces, numPoints, numVertexCoordinates, numAttributes

    if (geometryType === DracoModule.TRIANGULAR_MESH) {
      numFaces = dracoGeometry.num_faces()
    } else {
      numFaces = 0
    }

    numPoints = dracoGeometry.num_points()
    numVertexCoordinates = numPoints * 3
    numAttributes = dracoGeometry.num_attributes()

    if (!numAttributes) {
      console.error('No attribute found in the mesh')
    }

    // Get position attribute. Must exists
    const posAttId = wrapper.GetAttributeId(dracoGeometry, DracoModule.POSITION)

    if (posAttId === -1) {
      console.error('No position attribute found in the mesh')

      DracoModule.destroy(wrapper)
      DracoModule.destroy(dracoGeometry)
    }

    const posAttribute = wrapper.GetAttribute(dracoGeometry, posAttId)
    const posAttributeData = new DracoModule.DracoFloat32Array()
    wrapper.GetAttributeFloatForAllPoints(dracoGeometry, posAttribute, posAttributeData)

    // Get color attributes if exists
    const colorAttId = wrapper.GetAttributeId(dracoGeometry, DracoModule.COLOR)
    let colAttributeData

    if (colorAttId !== -1) {
      const colAttribute = wrapper.GetAttribute(dracoGeometry, colorAttId)
      colAttributeData = new DracoModule.DracoFloat32Array()
      wrapper.GetAttributeFloatForAllPoints(dracoGeometry, colAttribute, colAttributeData)
    }

    // Get tex coord attributes if exists
    const uvAttId = wrapper.GetAttributeId(dracoGeometry, DracoModule.TEX_COORD)
    let uvAttributeData

    if (uvAttId !== -1) {
      const uvAttribute = wrapper.GetAttribute(dracoGeometry, uvAttId)
      uvAttributeData = new DracoModule.DracoFloat32Array()
      wrapper.GetAttributeFloatForAllPoints(dracoGeometry, uvAttribute, uvAttributeData)
    }

    // Get normal attributes if exists
    const normalAttId = wrapper.GetAttributeId(dracoGeometry, DracoModule.NORMAL)
    let norAttributeData

    if (normalAttId !== -1) {
      const norAttribute = wrapper.GetAttribute(dracoGeometry, normalAttId)
      norAttributeData = new DracoModule.DracoFloat32Array()
      wrapper.GetAttributeFloatForAllPoints(dracoGeometry, norAttribute, norAttributeData)
    }

    let type
    if (geometryType === DracoModule.TRIANGULAR_MESH) {
      type = 'Mesh'
    } else if (geometryType === DracoModule.POINT_CLOUD) {
      type = 'Point cloud'
    } else {
      type = 'Unknown geometry type'
    }

    // Debug informations
    if (DEBUG.LOADER) {
      console.log('-- Draco loader (geometry infos)')
      console.log(`Type : ${type}`)
      console.log(`Num faces : ${numFaces.toString()}`)
      console.log(`Num points : ${numPoints.toString()}`)
      console.log(`Num attributes : ${numAttributes.toString()}`)
      console.log(`Color attribute : ${typeof colAttributeData !== 'undefined'}`)
      console.log(`Tex coord attribute : ${typeof uvAttributeData !== 'undefined'}`)
      console.log(`Normal attribute : ${typeof norAttributeData !== 'undefined'}`)
    }

    // Structure for converting to Three.js geometry later
    const geometryBuffer = {
      indices: [],
      vertices: [],
      normals: [],
      uvs: [],
      colors: []
    }

    for (let i = 0; i < numVertexCoordinates; i += 3) {
      geometryBuffer.vertices.push(
        posAttributeData.GetValue(i),
        posAttributeData.GetValue(i + 1),
        posAttributeData.GetValue(i + 2)
      )

      // Add color
      if (colorAttId !== -1) {
        geometryBuffer.colors.push(
          colAttributeData.GetValue(i),
          colAttributeData.GetValue(i + 1),
          colAttributeData.GetValue(i + 2)
        )
      } else {
        // Default is white
        geometryBuffer.colors.push(1.0, 1.0, 1.0)
      }

      // Add tex coord
      if (uvAttId !== -1) {
        geometryBuffer.uvs.push(
          uvAttributeData.GetValue(i),
          uvAttributeData.GetValue(i + 1)
        )
      }

      // Add normal
      if (normalAttId !== -1) {
        geometryBuffer.normals.push(
          norAttributeData.GetValue(i),
          norAttributeData.GetValue(i + 1),
          norAttributeData.GetValue(i + 2)
        )
      }
    }

    DracoModule.destroy(posAttributeData)

    if (colorAttId !== -1) {
      DracoModule.destroy(colAttributeData)
    }

    if (normalAttId !== -1) {
      DracoModule.destroy(norAttributeData)
    }

    // For mesh, we need to generate the faces
    if (geometryType === DracoModule.TRIANGULAR_MESH) {
      const ia = new DracoInt32Array()

      for (let i = 0; i < numFaces; ++i) {
        wrapper.GetFaceFromMesh(dracoGeometry, i, ia)
        geometryBuffer.indices.push(ia.GetValue(0), ia.GetValue(1), ia.GetValue(2))
      }

      DracoModule.destroy(ia)
    }

    DracoModule.destroy(wrapper)
    DracoModule.destroy(dracoGeometry)

    // Import data to Three.js geometry
    const geometry = new THREE.BufferGeometry()

    if (geometryType === DracoModule.TRIANGULAR_MESH) {
      geometry.setIndex(new (geometryBuffer.indices.length > 65535 ? THREE.Uint32BufferAttribute : THREE.Uint16BufferAttribute)(geometryBuffer.indices, 1))
    }

    geometry.addAttribute('position', new THREE.Float32BufferAttribute(geometryBuffer.vertices, 3))
    geometry.addAttribute('color', new THREE.Float32BufferAttribute(geometryBuffer.colors, 3))

    if (uvAttId !== -1) {
      geometry.addAttribute('uv', new THREE.Float32BufferAttribute(geometryBuffer.uvs, 2))
    }

    if (normalAttId !== -1) {
      geometry.addAttribute('normal', new THREE.Float32BufferAttribute(geometryBuffer.normals, 3))
    }

    return geometry
  }
}

export default DracoLoader
