export default function createCanvas (width = window.innerWidth, height = window.innerHeight) {
  const canvas = document.createElement('canvas')

  canvas.width = width * 2
  canvas.height = height * 2
  canvas.style.width = `${width / 10}rem`
  canvas.style.height = `${height / 10}rem`

  return canvas
}
