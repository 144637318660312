import FBO from 'gl/helpers/FBO'

import parseGeometry from 'utils/gl/parse-geometry'
import Window from 'signals/Window'

import BodyParticlesGUI from 'gl/gui/BodyParticlesGUI'

import simulationVs from 'gl/shaders/simulation.vert'
import simulationFs from 'gl/shaders/simulation.frag'
import renderVs from 'gl/shaders/body-particles-render.vert'
import renderFs from 'gl/shaders/body-particles-render.frag'

class BodyParticles extends THREE.Object3D {
  constructor ({ renderer, geometry, assets }) {
    super()

    this.renderer = renderer
    this.geometry = geometry
    this.assets = assets

    this.speed = 1.0

    this.resizeHandler = this.resizeHandler.bind(this)

    if (this.geometry instanceof THREE.BufferGeometry) {
      this.geometry = new THREE.Geometry().fromBufferGeometry(this.geometry)
    }

    const geometryData = parseGeometry(this.geometry)

    this.textureSize = parseInt(Math.sqrt(geometryData.length / 3))

    const positionsTexture = new THREE.DataTexture(geometryData, this.textureSize, this.textureSize, THREE.RGBFormat, THREE.FloatType)
    positionsTexture.minFilter = THREE.NearestFilter
    positionsTexture.magFilter = THREE.NearestFilter
    positionsTexture.needsUpdate = true

    const pointTexture = new THREE.Texture(this.assets.particle)
    pointTexture.needsUpdate = true

    this.simulationMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTime: { value: 0.0 },
        uPositions: { value: positionsTexture }
      },
      vertexShader: simulationVs,
      fragmentShader: simulationFs
    })

    this.renderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTime: { value: 0.0 },
        uAlphaTest: { value: 0.0 },
        uAlpha: { value: 0.0 },
        uWindowHeight: { value: Window.metrics.height },
        uPositions: { value: null },

        uSound: { value: new THREE.Vector4() },

        uWavePosition: { value: new THREE.Vector3() },
        uWaveRadius: { value: 0.0 },

        uNoiseAmplitude: { value: 0.8 },
        uNoiseFrequency: { value: 3.2 },
        uNoiseSpeed: { value: 3.0 },

        uFlyAmplitude: { value: 0.03 },
        uFlyFrequency: { value: 70.0 },
        uFlySpeed: { value: 0.5 },

        uPointSize: { value: 0.025 },
        uPointTexture: { value: pointTexture },
        ...THREE.UniformsLib.fog
      },
      vertexShader: renderVs,
      fragmentShader: renderFs,
      blending: THREE.AdditiveBlending,
      transparent: true,
      fog: true,
      depthWrite: false
    })

    this.fbo = new FBO({
      renderer,
      width: this.textureSize,
      height: this.textureSize,
      simulationMaterial: this.simulationMaterial,
      renderMaterial: this.renderMaterial
    })

    this.fbo.particles.renderOrder = 1
    this.add(this.fbo.particles)

    const waveGeometry = new THREE.SphereGeometry(1, 10, 10)
    const waveMaterial = new THREE.MeshBasicMaterial({ color: 0x0000FF, wireframe: true })

    this.wave = new THREE.Mesh(waveGeometry, waveMaterial)
    this.wave.visible = false
    this.wave.position.set(-1.15, 13.8, -0.11)
    this.waveRadius = 0.01
    this.wave.scale.multiplyScalar(this.waveRadius)
    this.add(this.wave)

    Window.resize.add(this.resizeHandler)

    this.gui = new BodyParticlesGUI({ body: this })
  }

  resizeHandler (width, height) {
    this.renderMaterial.uniforms.uWindowHeight.value = height
  }

  updateSpeed (speed, tweenDuration) {
    TweenMax.to(this, tweenDuration, { speed })
  }

  update (delta, time) {
    this.simulationMaterial.uniforms.uTime.value = time
    this.renderMaterial.uniforms.uTime.value += delta * this.speed / 6
    this.renderMaterial.uniforms.uWavePosition.value = this.wave.position
    this.renderMaterial.uniforms.uWaveRadius.value = this.wave.scale.x

    this.fbo.update()
  }
}

export default BodyParticles
