import raf from 'raf-loop'
import sono from 'sono'

import ExperienceSignals from 'signals/Experience'
import SensorSignals from 'signals/Sensor'

import Soundcloud from 'services/Soundcloud'

import BpmGraph from 'components/bpm-graph/bpm-graph'

import { BPM } from 'consts'

import createCanvas from 'utils/dom/create-canvas'
import { drawCircle, drawLinesCircle } from 'utils/dom/canvas'

const CONFIG = {
  SIZE: 380,
  STROKE_COLOR: 'rgba(213, 213, 213, 0.3)',
  NB_LINES: 70,
  LINE_LENGTH: 8,
  LINE_WIDTH: 1,
  PROGRESS: {
    COLORS: ['#ff9d32', '#f475a4', '#906bc9', '#1f60f3'],
    LINE_WIDTH: 2,
    RADIUS: 170
  },
  PROGRESS_DURATION: 3
}

export default Vue.extend({
  name: 'sensor-progress',

  template: require('./sensor-progress.html'),

  props: {
    assets: Object
  },

  data () {
    return {
      progress: 0,
      status: 'ACTIVATING PULSE SENSOR',
      completeOffset: 0,
      completeLength: 0
    }
  },

  mounted () {
    this.completeOffset = this.completeLength = this.$refs.path.getTotalLength()
    TweenMax.set(this.$refs.path, { autoAlpha: 0 }, 1)

    this.createCanvas()
    this.createTls()

    this.scale = 0
    this.rotation = 0

    this.isInTlEnded = false
    this.isSoundFound = false

    this.activatedSound = sono.createSound(this.assets.activated)
    this.calculatingSound = sono.createSound(this.assets.calculating)
    this.calculatingSound.volume = 0
    this.calculatingSound.loop = true
    this.completedSound = sono.createSound(this.assets.completed)

    this.update = this.update.bind(this)
    this.handEndedHandler = this.handEndedHandler.bind(this)
    this.updateSoundHandler = this.updateSoundHandler.bind(this)

    this.addSignals()

    this.raf = raf(this.update)
    this.raf.start()
  },

  methods: {
    createCanvas () {
      this.canvas = createCanvas(CONFIG.SIZE, CONFIG.SIZE)
      this.ctx = this.canvas.getContext('2d')

      this.$refs.canvas.appendChild(this.canvas)
    },

    createTls () {
      this.enterTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          this.graphTL.play()
          this.activatedSound.play()

          this.calculatingSound.play()
          this.calculatingSound.fade(1, 1)
        }
      })
      this.enterTl
        .to(this, 1, { scale: 1, ease: Power2.easeOut })
        .from(this.$refs.percentage, 1, { autoAlpha: 0 }, 0.3)
        .to(this, CONFIG.PROGRESS_DURATION, { progress: 1, ease: Sine.easeInOut }, 0.3)
        .from(this.$refs.status, 1, { autoAlpha: 0, y: 10, ease: Power2.easeOut }, 0.8)

      this.graphTL = new TimelineMax({
        paused: true,
        onComplete: () => SensorSignals.bpm.add(this.sensorBpmHandler)
      })
      this.graphTL
        .to(this.$refs.percentage, 1, { autoAlpha: 0, ease: Power2.easeOut }, 0)
        .to(this.$refs.status, 1, { autoAlpha: 0, y: -10, ease: Power2.easeOut }, 0)
        .set(this, { status: 'CALCULATING PULSE BEAT...' }, 0.8)
        .fromTo(this.$refs.status, 1, { autoAlpha: 0, y: 10 }, { autoAlpha: 1, y: 0, ease: Power2.easeOut, immediateRender: false }, 0.8)
        .from(this.$refs.graph.$el, 1, { autoAlpha: 0 }, 0.8)
        .addCallback(() => {
          this.repeatStatus = TweenMax.to(this.$refs.status, 0.8, { autoAlpha: 0.2, yoyo: true, repeat: -1, ease: Power2.easeInOut })
        }, 1.8)

      this.completeInTL = new TimelineMax({
        paused: true,
        onStart: () => {
          this.calculatingSound.fade(0, 1)
          this.completedSound.play()
        },
        onComplete: () => {
          this.isInTlEnded = true
          this.outReadyHandler()
        }
      })
      this.completeInTL
        .to(this.$refs.status, 1, { autoAlpha: 0, y: -10 }, 0)
        .to(this.$refs.graph.$el, 1, { autoAlpha: 0 }, 0)
        .set(this, { status: 'PULSE BEAT FOUND' }, 1)
        .fromTo(this.$refs.status, 1, { y: 10 }, { autoAlpha: 1, y: 0, ease: Power2.easeOut, immediateRender: false }, 1)
        .to(this, 1.5, { completeOffset: 0, ease: Power2.easeOut }, 1)
        .set(this.$refs.path, { autoAlpha: 1 }, 1)

      this.completeOutTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          ExperienceSignals.firstSoundFound.dispatch()
          this.activatedSound.destroy()
          this.calculatingSound.destroy()
          this.completedSound.destroy()
        }
      })
      this.completeOutTl
        .to(this.$el, 1, { x: 0, autoAlpha: 0 })
    },

    addSignals () {
      ExperienceSignals.handEnded.add(this.handEndedHandler)
      ExperienceSignals.updateSound.add(this.updateSoundHandler)
    },

    handEndedHandler () {
      this.enterTl.play()
    },

    updateSoundHandler () {
      this.isSoundFound = true
      this.outReadyHandler()
    },

    outReadyHandler () {
      if (this.isInTlEnded === true && this.isSoundFound === true) {
        this.completeOutTl.play()
      }
    },

    sensorBpmHandler ({ bpm }) {
      if (bpm >= BPM.MIN && bpm <= BPM.MAX) {
        Soundcloud.searchTrack(SensorSignals.currentBpm)

        if (this.repeatStatus) {
          this.repeatStatus.kill()
          TweenMax.to(this.$refs.status, 0.6, { autoAlpha: 1 })
        }

        TweenMax.delayedCall(2, () => {
          this.completeInTL.play()
          ExperienceSignals.bpmFound.dispatch()
        })

        SensorSignals.bpm.remove(this.sensorBpmHandler)
      }
    },

    draw () {
      this.ctx.save()

      this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2)
      this.ctx.scale(2, 2) // Retina

      this.ctx.save()
      this.ctx.scale(this.scale, this.scale)
      this.drawCircles()
      this.ctx.restore()

      this.drawProgress()

      this.ctx.restore()
    },

    drawCircles () {
      this.rotation += 0.005

      // Two circles
      this.ctx.save()
      this.ctx.rotate(this.rotation)
      drawCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: 115, endAngle: Math.PI * 1.5 })
      this.ctx.restore()

      drawCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: 155 })

      this.ctx.save()
      this.ctx.rotate(-this.rotation * 0.2)
      // Lines circle
      drawLinesCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: CONFIG.SIZE / 2, lineWidth: CONFIG.LINE_WIDTH, nbLines: CONFIG.NB_LINES, lineLength: CONFIG.LINE_LENGTH })
      this.ctx.restore()
    },

    drawProgress () {
      const end = (this.progress * Math.PI * 2)
      const partLength = end / CONFIG.PROGRESS.COLORS.length

      let start = -Math.PI * 0.5 // Modify origin

      for (let i = 0; i < CONFIG.PROGRESS.COLORS.length; i++) {
        const startColor = CONFIG.PROGRESS.COLORS[i]
        const endColor = CONFIG.PROGRESS.COLORS[(i + 1) % CONFIG.PROGRESS.COLORS.length]

        // x start / end of the next arc to draw
        const xStart = Math.cos(start) * CONFIG.PROGRESS.RADIUS
        const xEnd = Math.cos(start + partLength) * CONFIG.PROGRESS.RADIUS

        // y start / end of the next arc to draw
        const yStart = Math.sin(start) * CONFIG.PROGRESS.RADIUS
        const yEnd = Math.sin(start + partLength) * CONFIG.PROGRESS.RADIUS

        this.ctx.beginPath()

        const gradient = this.ctx.createLinearGradient(xStart, yStart, xEnd, yEnd)
        gradient.addColorStop(0, startColor)
        gradient.addColorStop(1, endColor)

        drawCircle({ ctx: this.ctx, lineWidth: CONFIG.PROGRESS.LINE_WIDTH, stroke: gradient, radius: CONFIG.PROGRESS.RADIUS, startAngle: start, endAngle: start + partLength })

        start += partLength
      }
    },

    update (delta) {
      this.ctx.clearRect(0, 0, CONFIG.SIZE * 2, CONFIG.SIZE * 2)
      this.draw()
    }
  },

  components: {
    'bpm-graph': BpmGraph
  }
})
