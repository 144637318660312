import { ASSETS_PATH } from 'consts'

import ExperienceSignals from 'signals/Experience'

import { randomInt } from 'utils/math'

export default Vue.extend({
  name: 'user-infos',

  template: require('./user-infos.html'),

  data () {
    return {
      ASSETS_PATH,
      userId: ('0000' + randomInt(1, 1000)).slice(-4),
      date: new Date().toLocaleDateString().replace(/\//g, '.'),
      time: ''
    }
  },

  mounted () {
    this.updateTime()
    this.createTls()

    this.tutorialEndedHandler = this.tutorialEndedHandler.bind(this)

    this.addSignals()
  },

  methods: {
    addSignals () {
      ExperienceSignals.tutorialEnded.add(this.tutorialEndedHandler)
      ExperienceSignals.isSoundcloudSearching.add(this.isSoundcloudSearchingHandler)
    },

    createTls () {
      this.enterTl = new TimelineMax({ paused: true, delay: 2 })
      this.enterTl
        .from(this.$el, 1, { autoAlpha: 0 })
        .staggerFrom([this.$refs.id, this.$refs.date, this.$refs.time], 1, { x: -10, autoAlpha: 0, ease: Power2.easeOut }, 0.1)
        .from(this.$refs.line, 1, { autoAlpha: 0, scaleX: 0, transformOrigin: 'right center', ease: Power2.easeOut }, 1.5)
    },

    tutorialEndedHandler () {
      this.enterTl.play()
    },

    isSoundcloudSearchingHandler (isSoundcloudSearching) {
      if (isSoundcloudSearching === true) {
        TweenMax.to(this.$refs.soundcloud, 0.8, { autoAlpha: 1 })
      } else {
        TweenMax.to(this.$refs.soundcloud, 0.8, { autoAlpha: 0, delay: 3 })
      }
    },

    updateTime () {
      setInterval(() => {
        const hours = ('00' + new Date().getHours() % 12).slice(-2)
        const minutes = ('00' + new Date().getMinutes()).slice(-2)
        const seconds = ('00' + new Date().getSeconds()).slice(-2)
        this.time = `${hours}:${minutes}:${seconds} ${(new Date().getHours() >= 12) ? 'PM' : 'AM'}`
      }, 1000)
    }
  },

  components: {

  }
})
