export default Vue.extend({
  name: 'footer',

  template: require('./footer.html'),

  data () {
    return {

    }
  },

  created () {

  },

  methods: {

  },

  components: {

  }
})
