class FBO {
  constructor ({ renderer, width, height, simulationMaterial, renderMaterial }) {
    this.renderer = renderer
    this.width = width
    this.height = height
    this.simulationMaterial = simulationMaterial
    this.renderMaterial = renderMaterial

    this.gl = this.renderer.getContext()

    if (!this.renderer.capabilities.floatVertexTextures) {
      console.error('[FBO] Float textures not supported or vertex shader cannot read textures')
    }

    this.setup()
  }

  setup () {
    // RenderTarget setup
    this.scene = new THREE.Scene()
    this.orthographicCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1)

    // Create a target texture
    const options = {
      wrapS: THREE.ClampToEdgeWrapping,
      wrapT: THREE.ClampToEdgeWrapping,
      minFilter: THREE.NearestFilter, // Important as we want to sample square pixels
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat, // Could be RGBAFormat
      type: THREE.FloatType, // Important as we need precise coordinates (not ints)
      stencilBuffer: false,
      depthBuffer: false
    }
    this.renderTarget = new THREE.WebGLRenderTarget(this.width, this.height, options)
    this.renderTarget.texture.generateMipmaps = false

    this.renderer.fboHelper.attach(this.renderTarget, 'Particles')

    // The simulation
    // Create a bi-unit quadrilateral and uses the simulation material to update the float texture
    this.simulationGeometry = new THREE.BufferGeometry()
    this.simulationGeometry.addAttribute('position', new THREE.BufferAttribute(new Float32Array([ -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0 ]), 3))
    this.simulationGeometry.addAttribute('uv', new THREE.BufferAttribute(new Float32Array([ 0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0 ]), 2))

    this.quadMesh = new THREE.Mesh(this.simulationGeometry, this.simulationMaterial)
    this.scene.add(this.quadMesh)

    // The particles
    // Create a vertex buffer of size width * height with normalized coordinates
    const size = this.width * this.height
    const vertices = new Float32Array(size * 3)

    for (let i = 0; i < size; i++) {
      const i3 = i * 3
      vertices[i3] = (i % this.width) / this.width
      vertices[i3 + 1] = (i / this.width) / this.height
    }

    // Create the particles geometry
    this.particlesGeometry = new THREE.BufferGeometry()
    this.particlesGeometry.addAttribute('position', new THREE.BufferAttribute(vertices, 3))

    // Render material is used to render the particles
    this.particles = new THREE.Points(this.particlesGeometry, this.renderMaterial)
    this.particles.frustumCulled = false
  }

  dispose () {
    this.renderTarget.texture.dispose()
    this.simulationGeometry.dispose()
    this.particlesGeometry.dispose()
  }

  update () {
    this.renderer.render(this.scene, this.orthographicCamera, this.renderTarget)
    this.particles.material.uniforms.uPositions.value = this.renderTarget.texture
  }

}

export default FBO
