import Signal from 'min-signal'

class Experience {
  constructor () {
    this.currentSound = null
    this.soundAnalysis = new THREE.Vector4()

    this.assetsLoaded = new Signal()
    this.messageEnded = new Signal()
    this.tutorialEnded = new Signal()
    this.handEnded = new Signal()
    this.waveEnded = new Signal()
    this.firstSoundFound = new Signal()
    this.bpmFound = new Signal()
    this.updateSpeed = new Signal()
    this.updateSound = new Signal()
    this.updateTrackInfos = new Signal()
    this.noSoundPlaying = new Signal()
    this.isSoundcloudSearching = new Signal()
  }
}

export default new Experience()
