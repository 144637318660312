import { ASSETS_PATH, BPM } from 'consts'
import BpmGraph from 'components/bpm-graph/bpm-graph'

import ExperienceSignals from 'signals/Experience'
import SensorSignals from 'signals/Sensor'

import { clamp } from 'utils/math'

export default Vue.extend({
  name: 'bpm-infos',

  template: require('./bpm-infos.html'),

  data () {
    return {
      ASSETS_PATH,
      bpm: '000'
    }
  },

  mounted () {
    this.createTls()

    this.bpmFoundHandler = this.bpmFoundHandler.bind(this)
    this.sensorBpmHandler = this.sensorBpmHandler.bind(this)

    this.addSignals()
  },

  methods: {
    addSignals () {
      SensorSignals.bpm.add(this.sensorBpmHandler)
      ExperienceSignals.bpmFound.add(this.bpmFoundHandler)
    },

    sensorBpmHandler ({ bpm }) {
      this.bpm = ('000' + bpm).slice(-3)
    },

    createTls () {
      this.enterTl = new TimelineMax({ paused: true, delay: 1 })
      this.enterTl
        .from(this.$el, 1.5, { autoAlpha: 0 })
    },

    bpmFoundHandler () {
      this.enterTl.play()
    }
  },

  components: {
    'bpm-graph': BpmGraph
  }
})
