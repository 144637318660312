/**
 * Linear interpolation between two values (lerping)
 *
 * @param  {number} v Value to interpolate
 * @param  {number} x First point
 * @param  {number} y Second point
 * @return {number}   Lerped value
 */
export default function lerp (v, x, y) {
  return x + ((y - x) * v)
}
