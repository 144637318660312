import BodyParticles from 'gl/entities/BodyParticles/BodyParticles'
import BodyVeins from 'gl/entities/BodyVeins/BodyVeins'

class Body extends THREE.Object3D {
  constructor ({ renderer, bodyGeometry, assets }) {
    super()

    const bodyMaterial = new THREE.MeshBasicMaterial({ color: 0xaaaaaa, wireframe: true, transparent: true, opacity: 0.07, fog: true })
    const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial)
    this.add(bodyMesh)

    this.bodyParticles = new BodyParticles({
      renderer,
      geometry: bodyGeometry,
      assets
    })
    this.add(this.bodyParticles)

    this.bodyVeins = new BodyVeins({ curves: assets.curves })
    this.add(this.bodyVeins)
  }

  update (delta, time) {
    this.bodyParticles.update(delta, time)
    this.bodyVeins.update(delta, time)
  }
}

export default Body
