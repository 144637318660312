import raf from 'raf-loop'

import ExperienceSignals from 'signals/Experience'

import Renderer from 'gl/core/Renderer'
import Camera from 'gl/core/Camera'
import Scene from 'gl/core/Scene'
import Clock from 'gl/helpers/Clock'

import { DEBUG } from 'consts'

export default Vue.extend({
  name: 'webgl',

  template: require('./webgl.html'),

  props: {
    assets: Object
  },

  mounted () {
    this.clock = new Clock()

    this.renderer = new Renderer({ canvas: this.$refs.canvas })

    this.camera = new Camera(75, window.innerWidth / window.innerHeight, 0.1, 10000, this.renderer.domElement)
    this.camera.controls.enabled = false

    this.scene = new Scene(this.renderer, this.camera, this.assets)

    this.update = this.update.bind(this)

    this.raf = raf(this.update)
    this.raf.start()
  },

  methods: {
    addSignals () {
      ExperienceSignals.tutorialEnded.add(this.tutorialEndedHandler)
    },

    update () {
      this.scene.update(this.clock.delta, this.clock.time)
    }
  }
})
