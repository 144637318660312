import vertexShader from 'gl/shaders/heart-tube.vert'
import fragmentShader from 'gl/shaders/heart-tube.frag'

class HeartMaterial extends THREE.RawShaderMaterial {

  constructor (parameters) {
    super(parameters)

    this.type = 'RawShaderMaterial'

    this.side = THREE.FrontSide
    this.fog = true

    this.extensions = {
      deriviatives: true
    }

    this.defines = {
      USE_FOG: true,
      PI: Math.PI,
      ...parameters.defines
    }

    this.animated = true
    this.rimLight = true
    this.color1 = '#ffb351' // Yellow
    this.color2 = '#ff3f61' // Red
    this.color3 = '#0056ff' // Blue
    this.color4 = '#181717' // Grey
    this.speed = 1.0
    this.equationMap = {
      basic: 1.0,
      cinquefoilKnot: 2.0,
      cyclone: 3.0,
      helix: 4.0,
      knot1: 5.0,
      knot2: 6.0,
      loopHelix: 7.0,
      star: 8.0
    }

    this.uniforms = {
      uThickness: { value: 0.05 },
      uTime: { value: 0.0 },
      uRimLight: { value: this.rimLight },
      uRimLightIntensity: { value: 1.8 },
      uColor1: { value: new THREE.Color(this.color1) },
      uColor2: { value: new THREE.Color(this.color2) },
      uColor3: { value: new THREE.Color(this.color3) },
      uColor4: { value: new THREE.Color(this.color4) },
      uMixStep1: { value: new THREE.Vector2(0.0, 0.0) },
      uMixStep2: { value: new THREE.Vector2(0.0, 0.0) },
      uMixStep3: { value: new THREE.Vector2(0.0, 0.0) },
      uEquation: { value: this.equationMap.knot2 },
      uEquationResize: { value: new THREE.Vector3(1.0, 1.0, 1.0) },
      uInstanceGap: { value: 1.85 },
      uSpeed: { value: 3.0 },
      uSound: { value: new THREE.Vector4() },
      ...parameters.uniforms,
      ...THREE.UniformsLib.fog
    }

    this.vertexShader = vertexShader
    this.fragmentShader = fragmentShader
  }

  update (delta, time) {
    if (this.animated) {
      this.uniforms.uTime.value = time * this.speed
    }
  }
}

export default HeartMaterial
