import { ASSETS_PATH } from 'consts'

export default [
  // Models
  { type: 'bin', id: 'body', url: `${ASSETS_PATH}/models/body.drc` },
  { type: 'bin', id: 'ring', url: `${ASSETS_PATH}/models/ring.drc` },

  // Textures
  { type: 'png', id: 'particle', url: `${ASSETS_PATH}/textures/particle.png` },

  // Videos
  { type: 'mp4', id: 'video', url: `${ASSETS_PATH}/videos/tutorial.mp4` },

  // Sounds
  { type: 'wav', id: 'heartbeat', url: `${ASSETS_PATH}/sounds/heartbeat.wav` },
  { type: 'wav', id: 'activated', url: `${ASSETS_PATH}/sounds/activated.wav` },
  { type: 'wav', id: 'calculating', url: `${ASSETS_PATH}/sounds/calculating.wav` },
  { type: 'wav', id: 'completed', url: `${ASSETS_PATH}/sounds/completed.wav` },
  { type: 'wav', id: 'camera', url: `${ASSETS_PATH}/sounds/camera.wav` },

  // Misc
  { type: 'json', id: 'curves', url: `${ASSETS_PATH}/misc/curves.json` }
]
