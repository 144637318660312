import OrbitControls from 'gl/helpers/OrbitControls'
import Window from 'signals/Window'

/**
 * Camera class
 */
class Camera extends THREE.PerspectiveCamera {

  /**
   * constructor method
   */
  constructor (fov, aspect, near, far, domElement) {
    super(fov, aspect, near, far)

    this.controls = new OrbitControls(this, domElement)
    this.controls.enabled = true

    this.resize = this.resize.bind(this)
    Window.resize.add(this.resize)
  }

  /**
   * update method
   * @param {number} delta Delta
   */
  update (delta) {
    this.controls.update(delta)
  }

  /**
   * resize method
   * @param {number} width  Width
   * @param {number} height Height
   */
  resize (width, height) {
    this.aspect = width / height
    this.updateProjectionMatrix()
  }
}

export default Camera
