import raf from 'raf-loop'

import ExperienceSignals from 'signals/Experience'

import ease from 'eases/sine-in-out'

import createCanvas from 'utils/dom/create-canvas'
import { drawCircle, drawLinesCircle } from 'utils/dom/canvas'

const CONFIG = {
  LINES_CIRCLE: {
    LINE_WIDTH: 1,
    NB_LINES: 400,
    LINE_LENGTH: 8
  },
  SIZE: 865,
  STROKE_COLOR: 'rgba(213, 213, 213, 0.3)'
}

export default Vue.extend({
  name: 'heart-canvas',

  template: require('./heart-canvas.html'),

  mounted () {
    this.rotation = 0

    this.createCanvas()
    this.createTls()

    this.update = this.update.bind(this)
    this.waveEndedHandler = this.waveEndedHandler.bind(this)

    this.addSignals()

    this.raf = raf(this.update)
    this.raf.start()
  },

  methods: {
    createCanvas () {
      this.canvas = createCanvas(CONFIG.SIZE, CONFIG.SIZE)
      this.ctx = this.canvas.getContext('2d')

      this.$refs.canvas.appendChild(this.canvas)
    },

    createTls () {
      this.enterTl = new TimelineMax({ paused: true })
      this.enterTl
        .from(this.$el, 2, { scale: 0, autoAlpha: 0, ease: Expo.easeOut })
    },

    addSignals () {
      ExperienceSignals.waveEnded.add(this.waveEndedHandler)
    },

    waveEndedHandler () {
      this.enterTl.play()
    },

    draw () {
      this.ctx.save()

      this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2)
      this.ctx.scale(2, 2) // Retina

      this.drawCircles()

      this.ctx.restore()
    },

    drawCircles () {
      this.rotation += 0.002

      this.ctx.save()
      this.ctx.rotate(this.rotation)
      drawLinesCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: CONFIG.SIZE / 2, lineWidth: CONFIG.LINES_CIRCLE.LINE_WIDTH, nbLines: CONFIG.LINES_CIRCLE.NB_LINES, lineLength: CONFIG.LINES_CIRCLE.LINE_LENGTH })
      this.ctx.restore()

      this.ctx.save()
      this.ctx.scale(ease(ExperienceSignals.soundAnalysis.z) + 0.3, ease(ExperienceSignals.soundAnalysis.z) + 0.3)
      drawCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: 300 })
      this.ctx.restore()

      this.ctx.save()
      this.ctx.scale(ease(ExperienceSignals.soundAnalysis.w) + 0.3, ease(ExperienceSignals.soundAnalysis.w) + 0.3)
      drawCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: 275 })
      this.ctx.restore()

      this.ctx.save()
      this.ctx.scale(ease(ExperienceSignals.soundAnalysis.x) + 0.4, ease(ExperienceSignals.soundAnalysis.x) + 0.4)
      drawCircle({ ctx: this.ctx, stroke: CONFIG.STROKE_COLOR, radius: 210 })
      this.ctx.restore()
    },

    update () {
      this.ctx.clearRect(0, 0, CONFIG.SIZE * 2, CONFIG.SIZE * 2)

      this.draw()
    }
  }
})
