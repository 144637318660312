import Folder from './Folder'

class HeartGUI extends Folder {
  constructor ({ heart }) {
    super({ folderName: 'Heart' })

    this.heart = heart

    // Geometry
    const meshFolder = this.folder.addFolder('Mesh')

    // --> Position
    const meshPositionFolder = meshFolder.addFolder('Position')
    meshPositionFolder.add(this.heart.mesh.position, 'x', -30, 30, 0.01).name('X')
    meshPositionFolder.add(this.heart.mesh.position, 'y', -30, 30, 0.01).name('Y')
    meshPositionFolder.add(this.heart.mesh.position, 'z', -30, 30, 0.01).name('Z')

    meshFolder.add(this.heart.mesh.scale, 'x', 0.01, 1, 0.001)
      .name('Scale')
      .onChange(v => {
        this.heart.mesh.scale.set(v, v, v)
      })

    meshFolder.add(this.heart, 'instanceNb', 0, this.heart.maxInstanceNb, 1)
      .name('Instances')
      .onChange(v => {
        this.heart.material.uniforms.uInstanceNb.value = v
      })

    // Material
    const materialFolder = this.folder.addFolder('Material')
    materialFolder.add(this.heart.material, 'animated').name('Animated')
    materialFolder.add(this.heart.material, 'speed', 0, 10, 0.01).name('Speed')
    materialFolder.add(this.heart.material, 'rimLight')
      .name('Rim light')
      .onChange(v => {
        this.heart.material.uniforms.uRimLight.value = v
      })
    materialFolder.add(this.heart.material.uniforms.uRimLightIntensity, 'value', 0, 50, 0.01).name('Rim intensity')
    materialFolder.add(this.heart.material.uniforms.uThickness, 'value', 0, 0.1, 0.001).name('Thickness')
    materialFolder.add(this.heart.material.uniforms.uInstanceGap, 'value', 0.001, 20, 0.001).name('Instance gap')
    materialFolder.addColor(this.heart.material, 'color1')
      .name('Color 1')
      .onChange(v => {
        this.heart.material.uniforms.uColor1.value = new THREE.Color(v)
      })
    materialFolder.addColor(this.heart.material, 'color2')
      .name('Color 2')
      .onChange(v => {
        this.heart.material.uniforms.uColor2.value = new THREE.Color(v)
      })
    materialFolder.addColor(this.heart.material, 'color3')
      .name('Color 3')
      .onChange(v => {
        this.heart.material.uniforms.uColor3.value = new THREE.Color(v)
      })

    const mixStep1Folder = materialFolder.addFolder('Mix step 1')
    mixStep1Folder.add(this.heart.material.uniforms.uMixStep1.value, 'x', 0, 1, 0.001).name('X')
    mixStep1Folder.add(this.heart.material.uniforms.uMixStep1.value, 'y', 0, 1, 0.001).name('Y')

    const mixStep2Folder = materialFolder.addFolder('Mix step 2')
    mixStep2Folder.add(this.heart.material.uniforms.uMixStep2.value, 'x', 0, 1, 0.001).name('X')
    mixStep2Folder.add(this.heart.material.uniforms.uMixStep2.value, 'y', 0, 1, 0.001).name('Y')

    materialFolder.add(this.heart.material.uniforms.uEquation, 'value', this.heart.material.equationMap).name('Equation')

    // --> Equation resize
    const equationResizeFolder = materialFolder.addFolder('Equation resize')
    equationResizeFolder.add(this.heart.material.uniforms.uEquationResize.value, 'x', 0, 1, 0.001).name('X')
    equationResizeFolder.add(this.heart.material.uniforms.uEquationResize.value, 'y', 0, 1, 0.001).name('Y')
    equationResizeFolder.add(this.heart.material.uniforms.uEquationResize.value, 'z', 0, 1, 0.001).name('Z')
  }
}

export default HeartGUI
