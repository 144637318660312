export default Vue.extend({
  name: 'logo',

  template: require('./logo.html'),

  props: {
    assets: Object
  },

  mounted () {

  },

  methods: {

  },

  components: {

  }
})
