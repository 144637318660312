import Signal from 'min-signal'

class Keyboard extends Signal {
  constructor () {
    super()

    this.keys = {
      SPACE: 32,
      ONE: 38,
      TWO: 233,
      THREE: 34,
      FOUR: 39
    }

    this.keydown = new Signal()
    this.keypress = new Signal()
    this.keyup = new Signal()

    this.handleKeydown = this.handleKeydown.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
    this.handleKeyup = this.handleKeyup.bind(this)

    document.addEventListener('keydown', this.handleKeydown, false)
    document.addEventListener('keypress', this.handleKeypress, false)
    document.addEventListener('keyup', this.handleKeyup, false)
  }

  handleKeydown (event) {
    this.keydown.dispatch(event)
  }

  handleKeypress (event) {
    this.keypress.dispatch(event)
  }

  handleKeyup (event) {
    this.keyup.dispatch(event)
  }
}

export default new Keyboard()
