import Window from 'signals/Window'

import vs from 'gl/shaders/mesh-line.vert'
import fs from 'gl/shaders/mesh-line.frag'

class MeshLineMaterial extends THREE.ShaderMaterial {
  constructor ({
    lineWidth = 1,
    resolution = new THREE.Vector2(Window.metrics.width, Window.metrics.height),
    color = 0xffffff,
    opacity = 1,
    alphaTest = 0.0,
    visibility = 1.0,
    map = null, // HTMLImageElement or THREE.Texture
    useMap = false,
    sizeAttenuation = true,
    offset = new THREE.Vector4(-1000, 1000, -1000, 1000),
    useDash = false,
    dashSize = 0, // Normalized
    gapSize = 0, // Normalized
    totalLineDistance = 0,
    loopDuration = 1.0, // Number of seconds to make a loop
    time = 0.0,
    transparent = false,
    side = THREE.FrontSide,
    blending = THREE.NormalBlending,
    depthTest = true,
    depthWrite = true,
    depthFunc = THREE.LessEqualDepth,
    fog = true,
    vertexShader = vs,
    fragmentShader = fs,
    uniforms = {}
  } = {}) {
    uniforms.lineWidth = uniforms.lineWidth || { value: lineWidth }
    uniforms.resolution = uniforms.resolution || { value: resolution }
    uniforms.color = uniforms.color || { value: new THREE.Color(color) }
    uniforms.opacity = uniforms.opacity || { value: opacity }
    uniforms.sizeAttenuation = uniforms.sizeAttenuation || { value: sizeAttenuation }
    uniforms.offset = uniforms.offset || { value: offset }
    uniforms.alphaTest = uniforms.alphaTest || { value: alphaTest }
    uniforms.visibility = uniforms.visibility || { value: visibility }

    if (map && !(map instanceof THREE.Texture)) {
      map = new THREE.Texture(map)
      map.magFilter = THREE.NearestFilter
      map.minFilter = THREE.NearestFilter
      map.needsUpdate = true
    }

    uniforms.map = uniforms.map || { value: map }
    uniforms.useMap = uniforms.useMap || { value: useMap }

    uniforms.useDash = uniforms.useDash || { value: useDash }
    uniforms.dashSize = uniforms.dashSize * totalLineDistance || { value: dashSize * totalLineDistance }
    uniforms.gapSize = uniforms.gapSize * totalLineDistance || { value: gapSize * totalLineDistance }

    uniforms.time = uniforms.time || { value: time }
    uniforms.loopDuration = totalLineDistance / uniforms.loopDuration || { value: totalLineDistance / loopDuration }

    uniforms.uSound = { value: new THREE.Vector4() }

    uniforms = {
      ...uniforms,
      ...THREE.UniformsLib.fog
    }

    super({
      uniforms,
      vertexShader,
      fragmentShader,
      transparent,
      side,
      blending,
      depthTest,
      depthWrite,
      depthFunc,
      fog
    })

    this.speed = 1.0

    this.onResize = ::this.onResize
    this.addSignals()
  }

  addSignals () {
    Window.resize.add(this.onResize)
  }

  removeSignals () {
    Window.resize.remove(this.onResize)
  }

  onResize (width, height) {
    this.uniforms.resolution.value = new THREE.Vector2(width, height)
  }

  dispose () {
    super.dispose()

    if (this.uniforms.map instanceof THREE.Texture) {
      this.uniforms.map.dispose()
    }
  }

  updateSpeed (speed, tweenDuration) {
    TweenMax.to(this, tweenDuration, { speed })
  }

  update (delta, time) {
    this.uniforms.time.value += delta * this.speed / 6
  }
}

export default MeshLineMaterial
