import HeartGeometry from './HeartGeometry'
import HeartMaterial from './HeartMaterial'
import HeartGUI from 'gl/gui/HeartGUI'

const CONFIG = {
  MIX_STEP_1: {
    x: 0.0,
    y: 0.56
  },
  MIX_STEP_2: {
    x: 0.37,
    y: 0.9
  },
  MIX_STEP_3: {
    x: 1,
    y: 2
  }
}

class Heart extends THREE.Object3D {
  constructor () {
    super()

    this.maxInstanceNb = 256
    this.instanceNb = 12
    this.isStartTlplayed = false
    this.isStartTlReversed = false

    const numSides = 12
    const subdivisions = 325

    this.geometry = new HeartGeometry({
      maxInstanceNb: this.maxInstanceNb,
      instanceNb: this.instanceNb,
      numSides,
      subdivisions
    })

    this.material = new HeartMaterial({
      defines: {
        lengthSegments: subdivisions.toFixed(1)
      },
      uniforms: {
        uInstanceNb: { value: this.instanceNb }
      }
    })

    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.position.set(-1.21, 13.73, 0.85)
    this.mesh.scale.multiplyScalar(0.68)
    this.mesh.frustumCulled = false
    this.add(this.mesh)

    this.createTls()

    this.gui = new HeartGUI({ heart: this })
  }

  createTls () {
    this.startTl = new TimelineMax({
      paused: true,
      onStart: () => { this.isStartTlplayed = true }
    })
    this.startTl
      .to(this.material.uniforms.uMixStep1.value, 2, {
        x: CONFIG.MIX_STEP_1.x,
        y: CONFIG.MIX_STEP_1.y
      }, 0)
      .to(this.material.uniforms.uMixStep2.value, 2, {
        x: CONFIG.MIX_STEP_2.x,
        y: CONFIG.MIX_STEP_2.y
      }, 0.5)
      .to(this.material.uniforms.uMixStep3.value, 2, {
        x: CONFIG.MIX_STEP_3.x,
        y: CONFIG.MIX_STEP_3.y
      }, 1)
  }

  start () {
    this.startTl.play()
  }

  updateSpeed (speed, tweenDuration) {
    if (speed === 0 && this.isStartTlReversed === false && this.isStartTlplayed === true) {
      this.startTl.reverse()
      this.isStartTlReversed = true
    } else if (speed !== 0 && this.isStartTlReversed === true && this.isStartTlplayed === true) {
      this.startTl.play()
      this.isStartTlReversed = false
    }
  }

  update (delta, time) {
    this.material.update(delta, time)
  }
}

export default Heart
