import EffectComposer from './EffectComposer'
import { RenderPass } from 'gl/postProcessing/passes'

class PostProcessing {
  constructor ({ scene, camera, renderer, config }) {
    this.name = 'PostProcessing'

    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.configuration = config

    this.passes = this.configuration.passes.filter(pass => pass.active)
    this.active = this.configuration.active

    this.composer = new EffectComposer(this.renderer)

    this.renderPass = new RenderPass(this.scene, this.camera)
    this.composer.addPass(this.renderPass)

    for (let i = 0, passesLength = this.passes.length; i < passesLength; i++) {
      this.composer.addPass(this.passes[i].constructor)

      if (i === passesLength - 1) {
        this.passes[i].constructor.renderToScreen = true
      }
    }
  }

  dispose () {
    for (let i = 0, passesLength = this.passes.length; i < passesLength; i++) {
      this.passes[i].constructor.dispose()
    }
    this.removeGUI()
    this.composer.dispose()
  }

  getPass (name) {
    return this.passes.find(pass => pass.name === name).constructor
  }

  getUniform (passName, uniformName) {
    return this.getPass(passName).material.uniforms[uniformName]
  }

  update (delta, time) {
    if (this.active && this.passes.length) {
      this.renderPass.scene = this.scene
      this.renderPass.camera = this.camera

      this.composer.render(delta, time)
    } else {
      this.renderer.render(this.scene, this.camera)
    }
  }
}

export default PostProcessing
