import clamp from 'utils/math/clamp'

class HeartGeometry extends THREE.InstancedBufferGeometry {
  constructor ({ maxInstanceNb = 32, instanceNb = 1, numSides = 8, subdivisions = 50, openEnded = false }) {
    super()

    this.maxInstancedCount = clamp(instanceNb, 0, maxInstanceNb)

    const radius = 1
    const length = 1

    // Create a base CylinderGeometry which handles UVs, end caps and faces
    const baseGeometry = new THREE.CylinderGeometry(radius, radius, length, numSides, subdivisions, openEnded)
    baseGeometry.rotateZ(Math.PI / 2)

    // Compute the radial angle for each position for later extrusion
    const tmpVec = new THREE.Vector2()
    const xPositions = []
    const angles = []
    const uvs = []
    const vertices = baseGeometry.vertices
    const faceVertexUvs = baseGeometry.faceVertexUvs[0]

    // Now go through each face and un-index the geometry
    for (let i = 0; i < baseGeometry.faces.length; i++) {
      const face = baseGeometry.faces[i]

      const { a, b, c } = face
      const v0 = vertices[a]
      const v1 = vertices[b]
      const v2 = vertices[c]
      const verts = [v0, v1, v2]
      const faceUvs = faceVertexUvs[i]

      // For each vertex in this face...
      for (let j = 0; j < verts.length; j++) {
        const v = verts[j]

        tmpVec.set(v.y, v.z).normalize()

        // The radial angle around the tube
        const angle = Math.atan2(tmpVec.y, tmpVec.x)
        angles.push(angle)

        // "arc length" in range [-0.5 .. 0.5]
        xPositions.push(v.x)

        // Copy over the UV for this vertex
        uvs.push(faceUvs[j].toArray())
      }
    }

    const posArray = new Float32Array(xPositions)
    const angleArray = new Float32Array(angles)
    const uvArray = new Float32Array(uvs.length * 2)
    const indexArray = new Float32Array(maxInstanceNb)

    // Unroll UVs
    for (let i = 0; i < posArray.length; i++) {
      const [u, v] = uvs[i]
      uvArray[i * 2 + 0] = u
      uvArray[i * 2 + 1] = v
    }

    // Set indexes
    for (let i = 0; i < maxInstanceNb; i++) {
      indexArray[i] = i
    }

    const posAttribute = new THREE.BufferAttribute(posArray, 1)
    const angleAttribute = new THREE.BufferAttribute(angleArray, 1)
    const uvAttribute = new THREE.BufferAttribute(uvArray, 2)
    const indexAttribute = new THREE.InstancedBufferAttribute(indexArray, 1, 1)

    this.addAttribute('position', posAttribute)
    this.addAttribute('angle', angleAttribute)
    this.addAttribute('uv', uvAttribute)
    this.addAttribute('idx', indexAttribute)

    // Dispose old geometry since we no longer need it
    this.dispose()
  }
}

export default HeartGeometry
