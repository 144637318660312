import SensorSignals from 'signals/Sensor'

import { BPM } from 'consts'

import { clamp, randomInt, map } from 'utils/math'

const config = {
  timeSpeed: 0.000005, // Speed of time BPM variations
  noBpmLuck: 0 // Luck that there is no bpm found
}

class Sensor {
  constructor () {
    this.timeBPM = this.getSinByTime(new Date().getTime() * config.timeSpeed)
    this.randomBPM = randomInt(BPM.MIN, BPM.MAX)
    this.generateData = this.generateData.bind(this)

    setInterval(this.generateData, randomInt(1000, 6000))
  }

  getSinByTime (time) {
    const sin = (Math.sin(time) + Math.sin(2.2 * time + 5.52) + Math.sin(2.9 * time + 0.93) + Math.sin(4.6 * time + 8.94)) / 4
    return map(sin, -1, 1, BPM.MIN, BPM.MAX) 
  }

  generateData () {
    this.timeBPM = this.getSinByTime(new Date().getTime() * config.timeSpeed)
    this.randomBPM = clamp(this.randomBPM + randomInt(-5, 5), BPM.MIN, BPM.MAX)

    const data = {
      signal: randomInt(450, 550),
      ibi: randomInt(300, 2500),
      bpm: parseInt((this.timeBPM + this.randomBPM) / 2)
    }

    if (Math.random() < config.noBpmLuck) {
      data.bpm = 0
      this.randomBPM = randomInt(BPM.MIN, BPM.MAX)
    }

    // console.info(`[Sensor] Generate ${data.bpm} BPM`)

    SensorSignals.currentBpm = data.bpm
    SensorSignals.bpm.dispatch(data)
  }
}

export default Sensor

