import raf from 'raf-loop'

import createCanvas from 'utils/dom/create-canvas'
import { map, clamp } from 'utils/math'

import ExperienceSignals from 'signals/Experience'

const CONFIG = {
  WIDTH: 220,
  HEIGHT: 60,
  NB_PULSE: 3,
  SCAN_BAR: {
    WIDTH: 8,
    SPEED: 1
  },
  COLORS: ['#FFA228', '#FF78A2', '#005DFF']
}

const data = [
  { cp1: [0, 37.4], cp2: [0, 37.4], p: [0, 37.4] },
  { cp1: [15.09, 37.48], cp2: [15.09, 37.48], p: [15.09, 37.48] },
  { cp1: [21.87, 37.71], cp2: [24.05, 34.48], p: [22.96, 36.09] },
  { cp1: [26.02, 36.78], cp2: [26.02, 35.4], p: [26.02, 36.09] },
  { cp1: [27.11, 0], cp2: [27.99, 1.7], p: [27.55, 1.01] },
  { cp1: [29.3, 61.71], cp2: [29.3, 58.2], p: [29.3, 59.86] },
  { cp1: [32.53, 36.85], cp2: [30.83, 46.94], p: [32.49, 38.63] },
  { cp1: [37.39, 28.01], cp2: [35.87, 27.61], p: [36.52, 27.78] },
  { cp1: [38.7, 29.4], cp2: [39.8, 39.55], p: [39.8, 38.4] },
  { cp1: [40.89, 39.98], cp2: [41.98, 37.13], p: [41.98, 37.13] },
  { cp1: [67.22, 36.97], cp2: [67.22, 36.97], p: [67.22, 36.97] },
  { cp1: [69.2, 36.97], cp2: [70.53, 34.36], p: [69.87, 35.67] },
  { cp1: [72.52, 35.23], cp2: [72.52, 35.23], p: [72.52, 35.23] }
]

export default Vue.extend({
  name: 'bpm-graph',

  template: require('./bpm-graph.html'),

  mounted () {
    this.createCanvas()

    this.speed = CONFIG.SCAN_BAR.SPEED

    this.scanBarX = 0
    this.gradientX = 0

    this.update = this.update.bind(this)

    this.raf = raf(this.update)
    this.raf.start()

    this.updateSpeedHandler = this.updateSpeedHandler.bind(this)

    this.addSignals()
  },

  methods: {
    addSignals () {
      ExperienceSignals.updateSpeed.add(this.updateSpeedHandler)
    },

    updateSpeedHandler (speed, tweenDuration) {
      TweenMax.to(this, tweenDuration, {
        speed: speed / 3,
        ease: Expo.easeOut
      })
    },

    createCanvas (x) {
      this.canvas = createCanvas(CONFIG.WIDTH, CONFIG.HEIGHT)
      this.ctx = this.canvas.getContext('2d')
      this.ctx.scale(2, 2) // Retina

      this.$refs.canvas.appendChild(this.canvas)
    },

    createGradient (x) {
      this.gradient = this.ctx.createLinearGradient(x, 0, CONFIG.WIDTH / CONFIG.NB_PULSE + x, 0)

      for (let i = 0; i < CONFIG.COLORS.length; i++) {
        const color = CONFIG.COLORS[i]
        this.gradient.addColorStop(clamp(i / (CONFIG.COLORS.length - 1), 0.01, 0.99), color)
      }

      this.gradient.addColorStop(0, '#505050')
      this.gradient.addColorStop(1, '#505050')
    },

    drawPulse (x) {
      this.ctx.strokeStyle = this.gradient
      this.ctx.lineWidth = 1
      this.ctx.lineCap = 'round'
      this.ctx.lineJoin = 'round'

      this.ctx.beginPath()

      for (let i = 0, dataLength = data.length; i < dataLength; i++) {
        const point = data[i]

        this.ctx.bezierCurveTo(point.cp1[0] + x, point.cp1[1], point.cp2[0] + x, point.cp2[1], point.p[0] + x, point.p[1])
        this.ctx.stroke()
      }
    },

    update (delta) {
      this.scanBarX = (this.scanBarX + 1.2 * this.speed) % CONFIG.WIDTH
      this.gradientX = map(this.scanBarX, 0, CONFIG.WIDTH, -CONFIG.WIDTH / CONFIG.NB_PULSE, CONFIG.WIDTH)

      this.createGradient(this.gradientX)

      for (let i = 0; i < CONFIG.NB_PULSE; i++) {
        this.drawPulse(i / CONFIG.NB_PULSE * CONFIG.WIDTH)
      }

      this.ctx.clearRect(this.scanBarX, 0, CONFIG.SCAN_BAR.WIDTH, this.canvas.height)
    }
  }
})
