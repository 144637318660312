import Curve from 'gl/core/Curve'
import MeshLine from 'gl/entities/MeshLine/MeshLine'
import { EXPERIENCE } from 'consts'

const CONFIG = {
  DIVISIONS: 300,
  GREY_COLOR: new THREE.Color(0xc9c9c9),
  WIDTH_CB: {
    FREQUENCY: 50,
    AMPLITUDE: 2
  },
  OFFSET: {
    MIN_X: -1.15,
    MAX_X: 3.2,
    MIN_Z: -3.8,
    MAX_Z: 2.5
  }
}

const veins = {
  vein_1: {
    color: new THREE.Color(0xffa228), // Orange
    gapSize: 0.25,
    loopDuration: EXPERIENCE.WAVE_DURATION,
    offset: new THREE.Vector4(CONFIG.OFFSET.MAX_X - 1.25, CONFIG.OFFSET.MAX_X, CONFIG.OFFSET.MIN_Z, CONFIG.OFFSET.MIN_Z)
  },
  vein_2: {
    color: new THREE.Color(0xff78a2), // Rose
    gapSize: 0.15,
    loopDuration: EXPERIENCE.WAVE_DURATION,
    offset: new THREE.Vector4(CONFIG.OFFSET.MAX_X - 1.25, CONFIG.OFFSET.MAX_X, CONFIG.OFFSET.MIN_Z, CONFIG.OFFSET.MIN_Z)
  },
  vein_3: {
    color: new THREE.Color(0x215bbc), // Blue
    gapSize: 0.3,
    loopDuration: -EXPERIENCE.WAVE_DURATION,
    offset: new THREE.Vector4(CONFIG.OFFSET.MIN_X, CONFIG.OFFSET.MIN_X, CONFIG.OFFSET.MAX_Z - 2.5, CONFIG.OFFSET.MAX_Z)
  }
}

class BodyVeins extends THREE.Object3D {
  constructor ({ curves }) {
    super()

    this.veins = curves.filter(vein => vein.name.includes('vein_'))
    this.curves = []
    this.meshLines = []

    this.defaultConfig = {
      sizeAttenuation: true,
      useDash: true,
      fog: true,
      lineWidth: 0.006,
      dashSize: 0.1
    }

    this.isStartTlEnded = false
    this.isColorGrey = false

    this.createCurves()
    this.createMeshLines()
    this.createTls()
  }

  createCurves () {
    for (let i = 0, veinsLength = this.veins.length; i < veinsLength; i++) {
      const vein = this.veins[i]

      const curve = new Curve({ points: vein.points, divisions: CONFIG.DIVISIONS })
      this.curves.push(curve)
    }
  }

  createMeshLines () {
    for (let i = 0, curvesLength = this.curves.length; i < curvesLength; i++) {
      const curve = this.curves[i]
      const vein = this.veins[i]

      const meshLine = new MeshLine(curve.geometry, {
        ...this.defaultConfig,
        ...veins[vein.name]
      }, p => this.widthCallback(p))

      this.add(meshLine)
      this.meshLines.push(meshLine)
    }
  }

  createTls () {
    this.startTl = new TimelineMax({
      paused: true,
      onComplete: () => { this.isStartTlEnded = true }
    })
    this.startTl
      .to(this.meshLines[0].material.uniforms.offset.value, 3, {
        w: CONFIG.OFFSET.MAX_Z
      }, 0)
      .to(this.meshLines[1].material.uniforms.offset.value, 3, {
        w: CONFIG.OFFSET.MAX_Z
      }, 0)
      .to(this.meshLines[0].material.uniforms.offset.value, 1, {
        x: CONFIG.OFFSET.MIN_X
      }, 3)
      .to(this.meshLines[1].material.uniforms.offset.value, 1, {
        x: CONFIG.OFFSET.MIN_X
      }, 3)
      .to(this.meshLines[2].material.uniforms.offset.value, 1, {
        y: CONFIG.OFFSET.MAX_X
      }, 5)
      .to(this.meshLines[2].material.uniforms.offset.value, 3, {
        z: CONFIG.OFFSET.MIN_Z
      }, 6)
  }

  start () {
    this.startTl.play()
  }

  widthCallback (p) {
    return (2 + Math.sin(CONFIG.WIDTH_CB.FREQUENCY * p)) * CONFIG.WIDTH_CB.AMPLITUDE
  }

  updateSpeed (speed, tweenDuration) {
    if (speed === 0 && this.isColorGrey === false && this.isStartTlEnded === true) {
      this.isColorGrey = true
      for (let i = 0, meshLinesLength = this.meshLines.length; i < meshLinesLength; i++) {
        TweenMax.to(this.meshLines[i].material.uniforms.color.value, tweenDuration, {
          r: CONFIG.GREY_COLOR.r,
          g: CONFIG.GREY_COLOR.g,
          b: CONFIG.GREY_COLOR.b
        })
      }
    } else if (speed !== 0 && this.isColorGrey === true && this.isStartTlEnded === true) {
      this.isColorGrey = false
      for (let i = 0, meshLinesLength = this.meshLines.length; i < meshLinesLength; i++) {
        TweenMax.to(this.meshLines[i].material.uniforms.color.value, tweenDuration, {
          r: veins[`vein_${i + 1}`].color.r,
          g: veins[`vein_${i + 1}`].color.g,
          b: veins[`vein_${i + 1}`].color.b
        })
      }
    }
  }

  update (delta, time) {
    for (let i = 0, meshLinesLength = this.meshLines.length; i < meshLinesLength; i++) {
      this.meshLines[i].update(delta, time + 3 * i)
    }
  }
}

export default BodyVeins
