import { DEBUG } from 'consts'
import Folder from './Folder'
import Camera from 'gl/core/Camera'
import Keyboard from 'signals/Keyboard'

const CONFIG = {
  HELPERS: {
    AXIS_SIZE: 5,
    GRID: {
      SIZE: 10,
      STEP: 10
    }
  }
}

class SceneGUI extends Folder {
  constructor ({ scene }) {
    super({ folderName: 'Scene' })

    this.scene = scene
    this.helpersVisible = false
    this.guiVisible = false

    this.createDebugCamera()
    this.createHelpers()

    this.toggleHelpers(this.helpersVisible)
    this.toggleGUI(this.guiVisible)

    // Common
    const commonFolder = this.folder.addFolder('Common')

    commonFolder.addColor(this.scene, 'environmentColor').name('Environment')
      .onChange(v => {
        this.scene.background = new THREE.Color(v)
        this.scene.fog.color = new THREE.Color(v)
        this.scene.renderer.setClearColor(v, 1)
      })
    commonFolder.addColor(this.scene, 'backgroundColor').name('Background')
      .onChange(v => {
        this.scene.background = new THREE.Color(v)
      })

    // Fog
    const fogFolder = commonFolder.addFolder('Fog')
    this.fogEnabled = (this.scene.fog.far > this.scene.fog.near)
    fogFolder.add(this, 'fogEnabled')
      .onChange(::this.toggleFog)
      .name('Enabled')

    fogFolder.add(this.scene.fog, 'near', 0, 30, 0.01).name('Near')
    fogFolder.add(this.scene.fog, 'far', 0, 30, 0.01).name('Far')
    fogFolder.addColor(this.scene, 'fogColor').name('Color')
      .onChange(v => {
        this.scene.fog.color = new THREE.Color(v)
        this.scene.background = this.scene.fog.color
        this.scene.backgroundColor = v
      })
    
    if (DEBUG.KEYBOARD) {
      this.createKeyboardShortcuts()
    }
  }

  createHelpers() {
    this.debugCameraHelper = new THREE.CameraHelper(this.debugCamera)
    this.debugCameraHelper.name = 'Camera helper'
    this.debugCameraHelper.material.fog = false

    this.cameraHelper = new THREE.CameraHelper(this.scene.camera)
    this.cameraHelper.name = 'Camera helper'
    this.cameraHelper.material.fog = false
    this.cameraHelper.visible = false

    this.currentCameraHelper = this.debugCameraHelper

    this.debugCamera.helper = this.debugCameraHelper
    this.scene.camera.helper = this.cameraHelper
    
    this.scene.add(this.debugCameraHelper)
    this.scene.add(this.cameraHelper)
  }

  createDebugCamera() {
    this.debugCamera = new Camera(75, window.innerWidth / window.innerHeight, 0.1, 10000, this.scene.renderer.domElement)
    this.debugCamera.controls.enabled = false
    this.debugCamera.controls.target.set(1.33, 13.6, 1.53)
    this.debugCamera.position.x = -5
    this.debugCamera.position.y = 10
    this.debugCamera.position.z = -5
  }

  createKeyboardShortcuts() {
    // Switch camera - 1
    Keyboard.keypress.add(({ keyCode }) => {
      switch (keyCode) {
        case Keyboard.keys.ONE: // Switch camera - 1
          this.switchCamera()
          break

        case Keyboard.keys.TWO: // Toggle fog - 2
          this.toggleFog()
          break

        case Keyboard.keys.THREE: // Toggle GUI - 3
          this.toggleGUI()
          break

        case Keyboard.keys.FOUR: // Toggle helpers - 4
          this.toggleHelpers()
        break  
      }
    })
  }

  switchCamera() {
    if (this.scene.currentCamera === this.scene.camera) {
      this.debugCameraHelper.visible = false
      this.cameraHelper.visible = true
      this.scene.currentCamera = this.debugCamera
      this.currentCameraHelper = this.cameraHelper
      this.debugCamera.controls.enabled = true
    } else {
      this.debugCameraHelper.visible = true
      this.cameraHelper.visible = false
      this.scene.currentCamera = this.scene.camera
      this.currentCameraHelper = this.debugCameraHelper
      this.debugCamera.controls.enabled = false
    }
  }

  toggleFog () {
    if (this.scene.fog.far === 0) {
      this.scene.fog.near = 0
      this.scene.fog.far = this.oldFogFar
    } else {
      this.oldFogFar = this.scene.fog.far
      this.scene.fog.near = 0.1
      this.scene.fog.far = 0
    }

    this.fogEnabled = (this.scene.fog.far > this.scene.fog.near)
  }

  toggleGUI(visible) {
    const fboEl = document.querySelector('#fboh-fbos-list')
    const guiEl = document.querySelector('.dg')

    if (fboEl.classList.contains('hide') || guiEl.classList.contains('hide') && visible) {
      fboEl.classList.remove('hide')
      guiEl.classList.remove('hide')
    } else {
      fboEl.classList.add('hide')
      guiEl.classList.add('hide')
    }
  }

  toggleHelpers(visible) {
    this.helpersVisible = (typeof visible !== 'undefined') ? visible : !this.helpersVisible
    
    this.scene.traverse(child => {
      const isHelper = child.name.toLowerCase().search('helper') !== -1

      if (isHelper && child !== this.scene.currentCamera.helper) {
        child.visible = this.helpersVisible
      }
    })
  }

  update(delta, time) {
    this.debugCamera.update(delta)
    
    this.scene.camera.updateMatrixWorld()
    this.debugCamera.updateMatrixWorld()

    this.currentCameraHelper.update()
  }
}

export default SceneGUI
