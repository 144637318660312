import querystring from 'querystring'
import sono from 'sono'

import { DEBUG, SOUNDCLOUD, BPM } from 'consts'
import tracksData from 'data/soundcloud_tracks'

import ExperienceSignals from 'signals/Experience'

const { BASE_URL, CLIENT_ID } = SOUNDCLOUD

const RESPONSE_ARRAY_BUFFER = 0
const RESPONSE_BLOB = 1
const RESPONSE_FORM_DATA = 2
const RESPONSE_JSON = 3
const RESPONSE_TEXT = 4

const PLAYED_SOUNDS = []
const MAX_PLAYED_SOUNDS_LENGTH = 3

class Soundcloud {
  static searchTracks (parameters) {
    if (DEBUG.SOUNDCLOUD_FAKE) {
      return new Promise((resolve, reject) => {
        resolve(tracksData)
      })
    } else {
      return this.get('tracks', RESPONSE_JSON, parameters)
    }
  }

  static getTrackStream (trackID) {
    return this.get(`tracks/${trackID}/stream`, RESPONSE_ARRAY_BUFFER)
  }

  static checkStatus (response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      console.error(`[Soundcloud] Bad status : ${response.statusText}`)
    }
  }

  static parseResponse (response, type) {
    switch (type) {
      case RESPONSE_ARRAY_BUFFER:
        return response.arrayBuffer()

      case RESPONSE_BLOB:
        return response.blob()

      case RESPONSE_FORM_DATA:
        return response.formData()

      case RESPONSE_JSON:
        return response.json()

      case RESPONSE_TEXT:
        return response.text()

      default:
        return response
    }
  }

  static get (endpoint, type = RESPONSE_JSON, parameters = {}) {
    let params = ''

    if (Object.keys(parameters).length) {
      params = `&${querystring.stringify(parameters)}`
    }

    const url = `${BASE_URL}/${endpoint}?client_id=${CLIENT_ID}${params}`

    return fetch(url)
      .then(this.checkStatus)
      .then(response => this.parseResponse(response, type))
      .catch(error => console.error(`[Soundcloud] Request failed : ${error}`))
  }

  static searchTrack (bpm = BPM.DEFAULT) {
    // console.info(`[Soundcloud] Search using : ${bpm} BPM`)
    ExperienceSignals.isSoundcloudSearching.dispatch(true)

    // Search tracks with a corresponding BPM
    Soundcloud.searchTracks()
      .then(tracks => {
        let nearestBpm = 0
        let nearestBpmTrack = null

        if (PLAYED_SOUNDS.length === MAX_PLAYED_SOUNDS_LENGTH) {
          PLAYED_SOUNDS.splice(0)
        }

        for (let i = 0, tracksLength = tracks.length; i < tracksLength; i++) {
          const difference = Math.abs(tracks[i].bpm - bpm)
          if (difference < Math.abs(nearestBpm - bpm) && PLAYED_SOUNDS.indexOf(tracks[i]) === -1) {
            nearestBpm = tracks[i].bpm
            nearestBpmTrack = tracks[i]
          }
        }

        const track = nearestBpmTrack
        PLAYED_SOUNDS.push(track)

        // console.info(`[Soundcloud] Track found : (ID) ${track.id} | (BPM) ${track.bpm} | (Title) ${track.title}`)

        Soundcloud.getTrackStream(track.id)
          .then(buffer => {
            const sound = sono.createSound(buffer)
            sound.loop = true

            ExperienceSignals.currentSound = sound
            ExperienceSignals.updateSound.dispatch({ sound, trackInfos: track })
            ExperienceSignals.isSoundcloudSearching.dispatch(false)
          })
      })
  }
}

export default Soundcloud
