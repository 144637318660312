import Signal from 'min-signal'
import debounce from 'lodash.debounce'

class Window {
  constructor () {
    this.metrics = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    this.resize = new Signal()
    this.blur = new Signal()
    this.focus = new Signal()

    this.handleResize = this.handleResize.bind(this)
    this.handleWindowBlur = this.handleWindowBlur.bind(this)
    this.handleWindowFocus = this.handleWindowFocus.bind(this)

    window.addEventListener('resize', debounce(this.handleResize, 100), false)
    window.addEventListener('blur', this.handleWindowBlur, false)
    window.addEventListener('focus', this.handleWindowFocus, false)
  }

  handleResize () {
    this.metrics.width = window.innerWidth
    this.metrics.height = window.innerHeight

    this.resize.dispatch(window.innerWidth, window.innerHeight)
  }

  handleWindowBlur () {
    this.blur.dispatch()
  }

  handleWindowFocus () {
    this.focus.dispatch()
  }
}

export default new Window()
