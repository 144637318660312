import ease from 'eases/sine-out'

import Curve from './Curve'

class AnimatedCurve extends Curve {
  constructor ({ points = [], divisions = 100, loop = true, loopDuration = 20, positionOffset = new THREE.Vector3(), helperColor = new THREE.Color(0xff0000) }) {
    super({ points, divisions, helperColor })

    this.progress = 0

    this.positionTarget = new THREE.Vector3()
    this.positionOffset = positionOffset

    this.loop = loop
    this.loopDuration = loopDuration

    this.playing = true
  }

  createGUI (folder) {
    super.createGUI(folder)

    folder.add(this, 'progress', 0, 1, 0.001)
      .onChange(v => {
        this.positionTarget = this.getPoint(this.progress)
      })
      .name('Progress')

    folder.add(this, 'loop')
      .name('Loop')
      .onChange(v => {
        this.playing = v
      })
    folder.add(this, 'loopDuration', 1, 120, 0.1).name('Loop duration')

    const positionOffsetFolder = folder.addFolder('Pos Y offset')
    positionOffsetFolder.add(this.positionOffset, 'x', -1000, 1000, 0.1).name('X')
    positionOffsetFolder.add(this.positionOffset, 'y', -1000, 1000, 0.1).name('Y')
    positionOffsetFolder.add(this.positionOffset, 'z', -1000, 1000, 0.1).name('Z')
  }

  update (delta, time) {
    if (this.playing) {
      if (this.loop) { // Looping
        this.progress %= 1
      } else if (this.progress >= 1) {
        this.playing = false
      }

      this.positionTarget = this.getPoint(this.progress).add(this.positionOffset)
    }
  }
}

export default AnimatedCurve
